.background-block {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 80px !important;
  padding-bottom: 80px !important;

  .background-header {
    font-family: $font-family-heading;
    /* From Figma doc */
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 49.6px; /* 112.727% */
    letter-spacing: -1.536px;
    /* End from Figma doc */
    color: $white;
    margin-bottom: 30px;

    &.mb-2 {
      margin-bottom: 30px !important;
    }
  }

  p {
    font-size: 14.4px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 166.667% */
    letter-spacing: 0.144px;
    width: 80%;
    margin: 0 auto;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
    .background-header {
      font-size: 55px;
      line-height: 60px; /* 109.091% */
      letter-spacing: -1.08px;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
      letter-spacing: -0.42px;
      width: 50%;
      max-width: 900px;
    }
  }
}