﻿.search-tabs {


    .nav-tabs {
        max-width: none !important;
        border-bottom: 2px solid $highlight-color;

        a {
            font-weight: 500;
        }
    }

    .nav-link {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    .tab-content {
        padding-top: 1rem;
        margin-bottom: 5rem;
    }

    .result-title {
        font-size: 1.2rem;
        font-family: $font-family-condensed;
        font-weight: 800;
    }


    .sku-result-container {
        display: inline-block;
        margin: 5px 0px;

        a {
            float: right;
        }

        .part-title {
            font-size: .85rem;
            color: #575757;
        }
    }

    .sku-number {
        background-color: $highlight-color;
        padding-left: 6px;
        white-space: nowrap;
    }

    .sku-result {
        border: 1px solid $highlight-color;
        padding-right: 5px;
        margin-bottom: 4px;
    }

    .page-link {
        font-size: 1.5rem;
        color: inherit !important;
        text-decoration: none;
        background-color: transparent;
        border-color: transparent;
        font-family: $font-family-condensed;
        font-weight: 800;
    }

    .page-item.active .page-link {
        color: #fff !important;
        background-color: #575757;
        border-color: #575757;
    }

    .sku-desc {
        width: 100%;
        padding: 2px 3px;
    }
}

#SearchForm .togglesearch-text {
    height: auto;
    line-height: 34px;
}





table.search-model-results {
    thead {
        tr {
            th {
                text-align: left;
                border-bottom: 1px solid #747474;
                padding-bottom: 2px;
                padding-left: 7px;
                padding-right: 7px;

                &:first-child {
                    padding-left: 0px;
                    border-bottom: 1px solid  $highlight-color;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding-left: 7px;
                padding-right: 7px;
            }

            td.sku-number {
                a {
                    color: #fff;
                    font-weight: 500;
                    float: none;
                }
            }
        }
    }

    .item-number-callout {
        font-weight: 500;
    }
}



.search-button-wrapper {

    button {
        text-transform: unset;
        font-weight: 400;
    }

    button:focus {
        outline: 0px;
    }
}