.block {
  .product-icon {
    max-width: 100%;
    &.media {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
      margin-bottom: 10px;

      img {
        background-color: $white;
        width: 60px;
        height: 60px;
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 16px;
        margin-right: 0;

        @include media-breakpoint-up(md) {
          margin-right: 19px;
        }
      }

      .media-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h2, h3, h4, h5, h6 {
          color: $black-alt;
          font-family: $font-family-body;
          font-size: 22px;
          font-style: normal;
          font-weight: 300;
          line-height: 33px; /* 150% */
          letter-spacing: -0.54px;
          text-align: left;
        }

        p {
          color: $medium-gray;
          text-align: left;
          margin-top: 6px;
        }

        .cta-arrow {
          color: $black;
          margin-top: 10px;
        }

      }

      @include media-breakpoint-up(md) {
        align-items: flex-start;

        .media-body {
          align-items: flex-start;

          h2, h3, h4, h5, h6 {
            text-align: left;
            margin-bottom: 0;
          }

          p {
            text-align: left;
          }
        }
      }

      &.row {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        .media-body {
          align-items: flex-start;
          text-align: left;

          img {
            margin-right: 19px;
          }

          p {
            text-align: left;
          }
        }

        @include media-breakpoint-up(lg) {
          padding-left: 0;
          padding-right: 0;
          margin-top: 0;
          margin-bottom: 10px;

          .col-lg-6 {
            max-width: 50%;
          }

          .media-body {
            text-align: left;
          }
        }
      }
    }
  }
}