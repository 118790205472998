.block {
    .hero-header {
        margin: 40px 2.0rem;
        text-shadow: 1px 1px 2px $dark-gray;
        padding: 0px 15px 15px 15px;


        @include media-breakpoint-down(md) {
            margin: 0px;
            padding-left:0px;
        }

        .container,
        .container-fluid {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 100px;
            padding-bottom: 80px;
            border-radius: 43px;
            box-shadow: 5px 5px 20px 10px rgba(0, 0, 0, 0.05);
            background-size: cover;
            color: #fff;

            .content-wrapper {
                padding-left: 2.5rem;
                padding-right: 2.5rem;

                @include media-breakpoint-down(md) {
                    padding-left: 1rem !important;
                    padding-right: 1rem !important;
                }
            }
        }

        &.dark-theme {
            .container,
            .container-fluid {
                background-color: $dark-gray;
                color: $white;
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 60px;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;

            .container,
            .container-fluid {
                padding-top: 120px;
                padding-bottom: 100px;

                .content-wrapper {
                    padding-left: 10%;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 80px;

            .container,
            .container-fluid {
                padding-top: 160px;
                padding-bottom: 120px;

                .content-wrapper {
                    p {
                    }
                }
            }
        }

        @media screen and (min-width:1200px) and (max-width:1480px) {
            padding-left: 2.0rem;
            padding-right: 2.0rem;

            .content-wrapper {
                padding-left: 2.0rem;
            }
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 100px;

            .container,
            .container-fluid {
                padding-top: 200px;
                padding-bottom: 140px;
            }

            .container-fluid {
                .row {
                    max-width: 1280px;

                    .content-wrapper {
                        //padding-left: 2.0rem;
                    }
                }
            }
        }
    }
}
