.block {
    .header-navigation {
        background-color: $transparent-gray;
        position: relative;
        box-shadow: 0 1px 0 rgba(158, 158, 158, 0.4);

        .container {
            .row {
                .logo-wrapper {

                    .brand-logo {
                        max-height: 50px;
                        margin: 12px 0 12px 0px;
                        max-width: 193px;
                    }
                }

                .navigation-menu {
                    padding: 28px 0;
                }

                .quick-link-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    padding: 24px 15px;

                    a {
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 12px; /* 80% */
                        .nav-icon {
                            padding-right: 5px;
                        }

                        .quantity {
                            padding-left: 13px;
                            text-decoration: underline;
                        }
                    }
                }

                ul {
                    &.top-level-navigation,
                    &.quick-links {
                        display: flex;
                        list-style-type: none;
                        margin-bottom: 0;
                        padding-left: 0;

                        li {
                            button {
                                color: $dark-gray;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 14px; /* 100% */
                                letter-spacing: 0.7px;
                                text-transform: uppercase;
                                margin-right: 11px;
                                padding-right: 8px;
                                padding-top: 2px;
                                border: none;
                                background-color: $very-light-gray;

                                &:hover,
                                &:active,
                                &:focus,
                                &:focus-within,
                                &:focus-visible {
                                    color: $aqua-blue;
                                }
                            }
                        }
                    }

                    &.top-level-navigation {
                        padding-left: 10px;

                        li {
                            position: relative;

                            button {
                                .caret-icon {
                                    padding-left: 5px;

                                    svg {
                                        fill: $black;
                                        transition: all 0.4s linear;
                                        margin-top: -2px;
                                    }
                                }
                            }

                            &.active {
                                button {
                                    color: $aqua-blue;

                                    .caret-icon {
                                        svg {
                                            fill: $aqua-blue;
                                            transform: scaleY(-1);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.quick-links {
                        li {
                            display: flex;
                            align-items: center;

                            a.cart-info {
                                text-decoration: none;
                            }

                            a.cart-info:hover {
                                text-decoration: none;
                            }

                            a .quantity {
                                text-decoration: none;
                            }

                            a .quantity:hover {
                                text-decoration: none;
                            }

                            button {
                                color: $black-alt;
                                text-transform: capitalize;
                                font-size: 15px;
                                margin-right: 0px;
                                padding: 0px;
                            }

                            .nav-icon {
                                padding-right: 5px;

                                svg path {
                                    stroke: $orange;
                                }
                            }

                            .quantity {
                                padding-left: 9px;
                            }
                        }
                    }
                }

                &.dropdown-wrapper {
                    .menu-panel {
                        position: absolute;
                        width: 100%;
                        left: 0;
                        background-color: $transparent-gray;
                        z-index: 200;
                        transition: all 0.2s linear;
                        box-shadow: -1px -1px 0 rgba(158, 158, 158, 0.40), 0 1px 0 rgba(158,158,158,0.40);
                        padding: 28px 15px;
                        display: none;

                        .featured-page {
                            p {
                                margin-bottom: 18px;
                            }

                            h2, h3, h4, h5, h6 {
                                margin-bottom: 18px;
                            }
                        }

                        .nav-list {
                            list-style-type: none;
                            margin-bottom: 0;
                            padding-left: 0;

                            li {
                                a {
                                    color: $aqua-blue;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px; /* 166.667% */
                                    letter-spacing: 0.18px;
                                    text-decoration: none;
                                    padding-right: 24px;
                                }

                                p {
                                    color: $medium-gray;
                                    margin-bottom: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }

                        &.active {
                            display: flex;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            display: none;
            opacity: 0;
        }

        @include media-breakpoint-up(md) {
            .container {
                .row {
                    flex-wrap: nowrap;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .container {
                .row {
                    &.dropdown-wrapper {
                        .menu-panel {
                            .row {
                                .col-3 {
                                    &.featured-page {
                                        padding-right: 60px;
                                        padding-left: 0;

                                        a {
                                            &.cta-arrow {
                                                color: $black;
                                                margin-top: 7px;

                                                &:after,
                                                &::after {
                                                    color: $black;
                                                    fill: $black;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .nav-list {
                                padding: 0 10px;

                                li {
                                    padding-bottom: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media screen and (min-width:991px) and (max-width:1300px) {
            .container {
                .row {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            .container {
                .row {
                    .navigation-menu {
                        ul {
                            &.top-level-navigation {
                                li {
                                    a {
                                        margin-right: 15px;
                                        padding-right: 14px;
                                    }
                                }
                            }
                        }
                    }

                    .quick-link-wrapper {
                        padding-top: 24px;
                        padding-bottom: 24px;
                    }

                    &.dropdown-wrapper {
                        .menu-panel {
                            padding: 54px 0 40px;

                            .row {
                                .col-3 {
                                    &.featured-page {
                                        padding-left: 15px;
                                    }
                                }
                            }

                            .nav-list {
                                li {
                                    a {
                                        font-size: 18px;
                                        line-height: 30px; /* 166.667% */
                                        color: $aqua-blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
a.cart-info {
    text-decoration: none;
}