.widen-video-block {
  margin-right: calc(2.0rem - 15px);
  margin-left: calc(2.0rem - 15px);
  h2 {
    &.video-heading {
      text-align: center;
      margin-bottom: 54px;
    }
  }

  .youtube-block {
    border-radius: 20px;
    margin-left: 0;
    margin-right: 0;
  }
}