.block {
  .enter-your-zip-code {
    transition: all 0.4s linear;
    background-color: $transparent-gray;
    padding: 24px 2.0rem;

    .three-column-block {
      margin-left: 0;
      margin-right: 0;
    }

    .cta-copy {
      h4 {
        margin-bottom: 14px;
      }

      p {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */
        margin-bottom: 0;
      }
    }

    form {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;

      input {
        max-width: 100%;

        &[type='search'] {
          border-radius: 5px;
          border: 1px solid rgba(158, 158, 158, 0.50);
          background: #ffffff;
          color: #6B6B6B;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 166.667% */
          letter-spacing: 0.18px;
          max-width: 100%;
          padding-right: 50px;
        }
      }

      .search-button-wrapper {
        max-width: 60px;

        button {
          background: url(/assets/images/aquasystems/arrow.svg) $aqua-blue no-repeat 50% 2px;
          background-size: 18px;
          width: 33px;
          height: 23px;
          flex-shrink: 0;
          border-radius: 6px;
          margin-left: -45px;
          margin-top: 9px;

          .btn-text {
            display: none;
          }

          svg {
            display: none;
          }

          &:hover,
          &:active,
          &:focus,
          &:focus-visible,
          &:focus-within {
            box-shadow: 3px 3px 8px 2px rgba(0, 0, 0, 0.10);
          }

          &:after,
          &::after {
            content: '';
            background: url(/assets/images/aquasystems/close-icon.png) transparent no-repeat 50% 50%;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      .cta-copy {
        max-width: 485px;
      }

      .form-group {
        margin-bottom: 0;
      }

      form {
        max-width: 100%;

        .search-button-wrapper {
          button {
            margin-top: 11px;
          }
        }

        input {
          &[type='search'] {
            //max-width: calc(100% - 50px);
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 24px 15px;

      .cta-copy {
        max-width: 400px;
      }

      form {
        max-width: 500px;
        input {
          max-width: 400px;
          &[type='search'] {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 166.667% */
            letter-spacing: 0.18px;
            border-radius: 5px;
            padding-right: 50px;
          }
        }

        span {
          &.btn {
            display: block;
            width: 11px;
            padding: 0.5rem 0;
            margin-left: 32px;

            svg {
              width: 11px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }

    @media screen and (min-width:991px) and (max-width:1280px) {
      .cta-copy {
        padding-right: 1.4rem;
      }
    }

    @include media-breakpoint-up(xl) {
      padding-top: 41px;
      padding-bottom: 31px;

      form {
        margin-left: 40px;
        input {
          max-width: 499px;
        }
      }
    }
  }
}