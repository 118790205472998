.breadcrumbs-container {
  background-color: $transparent-gray;

  .container {
    max-width: calc(100vw - 4.0rem) !important;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .breadcrumbs {
    font-family: $font-family-body;
    ol {
      padding: 0.75rem 1.0rem 0.5rem 0;
      li {
        position: relative;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #656972;

        &.breadcrumbDelineator {
            display: none;
        }

        a {
          font-size: 11px;
          color: $dark-gray;
        }

        &:not(:last-of-type) {
          padding-right: 24px;
          margin-right: 9px;

          &:after,
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 16px;
            width: 16px;
            background: url(/assets/images/aquasystems/caret-right.svg) transparent no-repeat 50% 50%;
            padding: 0 8px;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .container {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    @media screen and (min-width:991px) and (max-width:1300px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}