.block {
  &.search-results-page {
    .container {
      max-width: calc(100vw - 4.0rem) !important;
      margin-left: auto;
      margin-right: auto;
    }

    form {
      .form-group {
        svg {
          color: $black;
          height: 19px;
          margin: 0 23px 0 0;

          path {
            fill: $black;
          }
        }

        .search-container {
          align-items: center;
          justify-content: flex-start;

          .search-icon {
            display: none;
          }

          input {
            &[type='search'] {
              max-width: 100%;
              border-radius: 5px;
              border: 1px solid rgba(158, 158, 158, 0.50);
              background: $white;
              color: $medium-gray;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 166.667% */
              letter-spacing: 0.18px;
              padding-right: 50px;

              @include media-breakpoint-up(lg) {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }

          button {
            -webkit-appearance: none;
            appearance: none;
            background: url(/assets/images/aquasystems/arrow.svg) $aqua-blue no-repeat 50% 2px;
            background-size: 18px;
            width: 33px;
            height: 23px;
            flex-shrink: 0;
            border: 0;
            border-radius: 6px;
            margin-top: 0;
            margin-left: -45px;
            &:hover,
            &:active,
            &:focus,
            &:focus-visible,
            &:focus-within {
              border: 0;
              box-shadow: 3px 3px 8px 2px rgba(0, 0, 0, 0.10);
            }
          }

          span {
            &.btn {
              display: block;
              width: 11px;
              padding: 1rem 0;
              margin-left: 32px;

              svg {
                width: 11px;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }

    .simple-button-block {
      background: transparent;
      display: inline-block;
      border-radius: 4.091px;
      border: 1.636px solid #000;
      margin: 22px auto;

      &:active,
      &:hover,
      &:focus,
      &:focus-within,
      &:focus-visible {
        background-color: $white;
      }
    }

    .search-results-list {
      hr {
        border-top: 1.5px solid rgba(158, 158, 158, 0.30);
        margin-top: 36px;
        margin-bottom: 36px;
      }

      small {
        display: block;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-left: 2px;
        padding-bottom: 23px;
      }

      .media {
        display: flex;
        flex-direction: column;
        margin-top: 0;

        .d-block {
          &.result-title {
            width: 100%;
            height: 227.2px;
            border-radius: 12px;
            background: rgba(0, 0, 0, 0.20);
            overflow: hidden;

            img {
              display: block;
              background-color: transparent;
              width: 100%;
              height: auto;
              object-fit: cover;
              flex-shrink: 0;
              border-radius: 0;
              padding: 0;
              margin-bottom: 0;
            }
          }
        }

        .media-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          h3 {
            font-family: $font-family-heading;
            font-size: 30.8px;
            font-style: normal;
            font-weight: 400;
            line-height: 34.72px; /* 112.727% */
            letter-spacing: -1.075px;
          }

          p {
            color: $dark-gray;
            text-align: left;
          }

          .cta-arrow {
            display: inline-flex;
            position: relative;
            text-align: left;
            font-size: 14.4px;
            line-height: 24px; /* 166.667% */
            text-decoration: none;
            padding-left: 0px;
            padding-right: 46px;
            padding-bottom: 21px;
            margin-top: 0;
            transition: all 0.1s linear;
            border-bottom: solid 3px transparent;

            &:after,
            &::after {
              content: '';
              position: absolute;
              background-image: url(/assets/images/aquasystems/right-arrow.svg);
              background-repeat: no-repeat;
              width: 14px;
              height: 14px;
              bottom: 23px;
              right: 4px;
            }

            &:hover {
              border-bottom: solid 3px #4BC6E8;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .search-results-list {
        .container {
          padding-left: 2.0rem;
          padding-right: 2.0rem;
        }

        .media {
          .d-block {
            &.result-title {
              img {
                width: 100%;
                max-width: 100%;
                height: 227.2px;
              }
            }
          }
          .media-body {
            margin-top: 24px;
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
      .search-results-list {
        .media {
          flex-direction: row;
          align-items: center;
          .d-block {
            &.result-title {
              width: 25%;
              height: auto;
              margin-right: 24px;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      width: 100%;

      h2 {
        margin-bottom: 0;
      }

      .search-container {
        justify-content: center;

        input {
          &[type='search'] {
            max-width: 370px;
          }
        }
      }

      .search-results-list {
        .media {
          margin-top: 0;

          .d-block {
            &.result-title {
              width: 304px;
              margin-right: 36px;
            }
          }

          .media-body {
            p {
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px; /* 166.667% */
              letter-spacing: 0.18px;
              margin-bottom: 1rem;
            }

            .cta-arrow {
              margin-top: 12px;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: 100%;
      margin: 0 auto;
      padding-bottom: 170px;
      padding-top: 170px;

      form {
        margin-top: 56px;
        margin-bottom: 47px;

        .form-group {
          &.row {
            .col {
              flex-direction: row;
              align-items: center;
            }
          }
        }
      }

      .search-results-list {
        .product-icon {
          &.media {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;

            .d-block {
              &.result-title {
                margin-right: 41px;

                img {
                  min-width: 300px;
                  max-width: 100%;
                  height: 227.2px;
                  object-fit: cover;
                }
              }
            }

            .media-body {
              max-width: 600px;

              h3 {
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: 44px; /* 110% */
                letter-spacing: -0.72px;
                margin-bottom: 12px;
              }

              .cta-arrow {
                font-size: 18px;
                line-height: 21.33px;
                &:after,
                &::after {
                  width: 18px;
                  height: 16px;
                  bottom: 21px;
                  right: 4px;
                }
              }
            }
          }
        }

      }
    }

    @include media-breakpoint-up(xl) {
      width: 1006px;
    }
  }
}