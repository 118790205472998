.block {
    .featured-related-products {
        max-width: 100%;
        padding-left: 2.0rem;
        padding-right: 2.0rem;

        h2,
        .medium-subhead {
            text-align: center;
        }

        h1, h2, h3, h4, h5, h6, p, div, span {
            &.medium-subhead {
                margin-bottom: 23px;
            }
        }

        .block-grid-xs-1 {
            display: grid;
            grid-template-columns: repeat(1,1fr);
            gap: 24px;
            max-width: 100%;
            margin: 0 auto;

            &:before,
            &::before {
                content: '';
                display: none;
            }

            a {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-decoration: none;
                border-radius: 20px;
                border: 1px solid rgba(158, 158, 158, 0.50);
                background: rgba(255, 255, 255, 0.75);
                width: 100%;
                margin: 20px 0;
                padding: 42px 32px;

                &:hover {
                    box-shadow: 7px 7px 35px 0 rgba(0, 0, 0, 0.1);

                    .slat-text {
                        color: $black;
                    }

                    .small-subhead {
                        color: $black-alt;
                    }
                }

                .small-subhead {
                    margin-bottom: 0;
                }
            }

            .slat-text {
                order: 2;
                text-align: left;
                font-family: $font-family-heading;
                font-size: 26px;
                font-style: normal;
                font-weight: 400;
                line-height: 33.6px; /* 129.231% */
                letter-spacing: -0.72px;
                margin-bottom: 20px;
                margin-left: 0;
                padding-top: 10px;
                min-height: 111px;
            }

            img {
                display: block;
                object-fit: cover;
                background-attachment: unset;
                order: 3;
                width: 100%;
                height: auto;
                border-radius: 12px;
                background: $border-gray;
                padding-bottom: 0 !important;
                overflow: hidden;
                margin-bottom: 22px;
                margin-left: 0;
                margin-right: 0;
            }

            button {
                order: 4;

                &.cta-arrow {
                    color: $black;
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: transparent;
                    border: 0;
                    border-bottom: solid 3px transparent;
                    margin-top: 8px;
                    padding-left: 2px;
                    padding-right: 36px;

                    &:after,
                    &::after {
                        top: 6px;
                        right: 0;
                    }

                    &:hover {
                        border-color: $accent-blue;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            .block-grid-xs-1 {
                grid-template-columns: repeat(2,1fr);
            }
        }

        @include media-breakpoint-up(lg) {
            padding-left: 15px;
            padding-right: 15px;

            h2 {
                &.section-title {
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 52.8px; /* 110% */
                    letter-spacing: -1.92px;
                }
            }

            .block-grid-xs-1 {
                grid-template-columns: repeat(3,1fr);
                gap: 36px;
                max-width: 1280px;
                margin: 50px auto 0;

                a {
                    max-width: 380px;
                    padding: 48px 50px;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            .block-grid-xs-1 {
                gap: 52px;

                a {
                    padding: 60px 62px 34px;
                }
            }
        }
    }
}
