.block {
  &.products-listing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2.0rem;
    padding-right: 2.0rem;

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    h2.products-listing-title {
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 49.6px; /* 112.727% */
      letter-spacing: -1.536px;
      //padding-left: 24px;
      //padding-right: 24px;
      padding-bottom: 24px;
    }

    .products-listing-text {
      font-size: 14.4px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 166.667% */
      letter-spacing: 0.144px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 45px;
    }

    .product-silo-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      padding-right: 0;

      .product-listing-container {
        margin: 0 -30px 0 -30px;

        .product-tile {
          padding: 3px 0 30px;


          .product-tile-wrapper {
            background-color: transparent;

            a {
              display: block;
            }

            img {
              display: block;
              width: 100%;
              object-fit: cover;

              &.img-fluid {
                height: auto;
                border-radius: 12px;
              }
            }

            .product-tile-text {
              color: $dark-gray;
              padding: 0;

              h3 {
                font-size: 30.8px;
                font-style: normal;
                font-weight: 400;
                line-height: 34.72px; /* 112.727% */
                letter-spacing: -1.075px;
                padding-top: 29px;
                padding-bottom: 11px;
              }

              p {
                font-size: 14.4px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 166.667% */
                letter-spacing: 0.144px;
              }
            }

            .cta-arrow {
              display: inline-block;
              position: relative;
              text-align: left;
              font-size: 14.4px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding-left: 0px;
              padding-right: 30px;
              padding-bottom: 21px;
              margin-top: 0;
              transition: all 0.1s linear;
              border-bottom: solid 3px transparent;

              &:after,
              &::after {
                content: '';
                position: absolute;
                background-image: url(/assets/images/aquasystems/right-arrow.svg);
                background-repeat: no-repeat;
                width: 14.4px;
                height: 14.4px;
                bottom: 21px;
                right: 4px;
              }

              &:hover {
                border-bottom: solid 3px #4BC6E8;
              }
            }

            &:hover {
              .product-tile-text {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &.products-listing {
      .product-silo-main {
        .product-listing-container {
          .product-tile {
            padding: 30px 31px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.products-listing {
      .product-listing-container {
        h1, h2, h3, h4, h5, h6, p, ul, ol {
          max-width: 800px;
          padding-left: 0;
          padding-right: 0;
        }
      }

      .products-listing-title {
        font-size: 55px;
        line-height: 62px; /* 112.727% */
        letter-spacing: -1.92px;
        max-width: 820px;
      }

      .products-listing-text {
        font-size: 18px;
        line-height: 30px; /* 166.667% */
        letter-spacing: 0.18px;
        max-width: 820px;
        padding-bottom: 77px;
        padding-left: 0;
        padding-right: 0;
      }

      .product-silo-main {
        .product-listing-container {
          .product-tile {
            .product-tile-wrapper {
              max-width: 380px;


              .product-tile-text {
                p {
                  font-size: 18px;
                  line-height: 30px; /* 166.667% */
                  letter-spacing: 0.18px;
                }

                h3 {
                  margin-bottom: 0;
                  padding-top: 42px;
                  padding-bottom: 10px;
                }
              }

              .cta-arrow {
                font-size: 18px;
                line-height: 21.33px;
                padding-top: 3px;
              }
            }
          }
        }

        .results-text {
          margin-top: 74px;
        }
      }
    }
  }
}