.feature-carousel-block {
  padding-bottom: 40px;
  padding-top: 26px;
  margin-left: 2.0rem;
  margin-right: 2.0rem;
  background-color: transparent;

  .carousel-inner {
    background-color: $white;
    border-radius: 20px;
    border: 1px solid rgba(158, 158, 158, 0.50);;
    overflow: hidden;
    transition: all 0.4s linear;
    .carousel-item {
      height: 100%;
      padding: 10px 0;
      .row {
        justify-content: center;
        min-height: 430px;
        .carousel-slide-copy {
          justify-content: center;
        }
      }
    }
  }

  ol.carousel-indicators {
    bottom: -24px;
    li {
      width: 10px;
      height: 10px;
      margin: 0px 8px;
      border-radius: 50%;
      background-color: $blue;
    }
  }

  .carousel-control-prev {
    .carousel-control-prev-icon {
      position: relative;
      width: 30px;
      height: 18px;
      svg {
        display: none;
      }

      &:before,
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        background-image: url(/assets/images/aquasystems/left-arrow.svg);
        background-repeat: no-repeat;
        width: 20px;
        height: 18px;
        z-index:100;
      }
    }
  }

  .carousel-control-next {
    .carousel-control-next-icon {
      position: relative;
      width: 30px;
      height: 18px;
      svg {
        display: none;
      }

      &:after,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: auto;
        background-image: url(/assets/images/aquasystems/right-arrow.svg);
        background-repeat: no-repeat;
        width: 20px;
        height: 18px;
        z-index: 100;
      }
    }
  }

  .feature-carousel-slide-copy {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h4 {
      &.category-name {
        color: $black;
        font-family: $font-family-body;
        font-size: 15px;
        line-height: 15.6px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 16px;
      }
    }

    p {
      text-align: center;
      margin-bottom: 10px;
    }

    .ffs-green {
      display: inline-block;
      background: none;
      font-family: $font-family-body;
      font-weight: 400;
      color: $black;
      border: solid 1.636px $black;
      border-radius: 4px;
      margin-top: 20px;
      padding: 13px 30px;

      svg {
        display: none;
      }

      .btn-text {
        padding-right: 0;
        font-size: 18px;
      }

      &:active,
      &:hover,
      &:focus,
      &:focus-within,
      &:focus-visible {
        background-color: $warm-gray;
        color: $white;
      }
    }
  }

  &.image-right,
  &.image-left {
    .row {
      &.feature-carousel-slide {
        .feature-carousel-slide-image {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 20px;
        }
      }
    }
  }

  &.testimonial {
    .row {
      &.feature-carousel-slide {
        flex-direction: column;

        .col-md-7 {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .feature-carousel-slide-image {
          display: none;
        }

        .feature-carousel-slide-copy {
          align-items: center;
          text-align: center;
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;

          h1, h2, h3, h4, h5, h6, p {
            text-align: center;
          }

          .customer-rating {
            color: $dark-gray;
            margin-top: 40px;
          }

          .simple-button-block {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .carousel-control-prev {
      .carousel-control-prev-icon {
        left: 10px;
        right: auto;
      }
    }

    .carousel-control-next {
      .carousel-control-next-icon {
        left: auto;
        right: 10px;
      }
    }

    .row {
      &.feature-carousel-slide {
        .feature-carousel-slide-copy {
          padding-left: 1.8rem;
          padding-right: 1.8rem;

          h2 {
            font-size: 28px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {

    max-width: 1030px;
    margin-left: 2.0rem;
    margin-right: 2.0rem;
    margin-bottom: 100px;

    .carousel-control-prev {
      .carousel-control-prev-icon {
        left: -10px;
      }
    }

    .carousel-control-next {
      .carousel-control-next-icon {
        right: -10px;
      }
    }

    .feature-carousel-slide-copy {
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 50px;
      padding-top: 50px;

      h4 {
        &.category-name {
          text-align: left;
        }
      }

      h2 {
        text-align: left;
      }

      p {
        text-align: left;
      }
    }

    &.image-left,
    &.image-right {
      .row {
        &.feature-carousel-slide {
          .feature-carousel-slide-image {
            img {
              max-width: 100%;
            }
          }
        }
      }
    }

    &.image-left {
      .row {
        &.feature-carousel-slide {
          .feature-carousel-slide-image {
            justify-content: flex-end;
            padding-right: 30px;
          }
        }
      }
    }

    &.image-right {
      .row {
        &.feature-carousel-slide {
          .feature-carousel-slide-image {
            justify-content: flex-start;
            padding-left: 24px;
          }

          .feature-carousel-slide-copy {
            padding-left: 30px;
          }
        }
      }
    }

    &.testimonial {
      .row {
        &.feature-carousel-slide {
          .feature-carousel-slide-copy {
            max-width: 60%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 1030px;
    &.image-left {
      .row {
        &.feature-carousel-slide {
          .feature-carousel-slide-image {
            justify-content: flex-end;
            padding-right: 30px;
            img {
              max-width: 302px;
            }
          }
        }
      }
    }

    &.image-right {
      .row {
        &.feature-carousel-slide {
          .feature-carousel-slide-image {
            justify-content: flex-start;
            padding-left: 30px;
          }
        }
      }
    }

    &.testimonial {
      .row {
        &.feature-carousel-slide {
          .feature-carousel-slide-copy {
            max-width: 50%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-left: auto;
    margin-right: auto;
  }
}

