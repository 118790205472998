.block {
    .eyebrow-navigation {
        background-color: $very-dark-gray;
        color: $white;
        padding-top: 10px;
        padding-bottom: 10px;
        z-index: 1021;
        position: relative;

        .navigation-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        a {
            color: $white;
            font-family: $font-family-body;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 160% */
            white-space: nowrap;
            text-decoration: none;
        }

        .eyebrow-menu {
            .menu {
                margin-bottom: 0;
                margin-right: 48px;
                padding-left: 0;

                li {
                    display: inline-block;
                    list-style-type: none;
                    padding-right: 24px;

                    a {
                        &:hover,
                        &:active,
                        &:focus,
                        &:focus-within,
                        &:focus-visible {
                            text-decoration: underline;
                        }
                    }

                    &.location-search-toggle {
                        a {
                            span {
                                &.toggle-icon {
                                    display: inline-block;
                                    background: url(/assets/images/aquasystems/chevron-up.svg) transparent no-repeat center center;
                                    background-size: 12px;
                                    width: 18px;
                                    height: 12px;
                                    transform: scaleY(-1);
                                    position: relative;
                                    top: 2px;
                                }
                            }

                            &:hover,
                            &:active,
                            &:focus,
                            &:focus-visible,
                            &:focus-within {
                                text-decoration: underline;
                            }
                        }

                        &.active {
                            a {
                                span {
                                    &.toggle-icon {
                                        transform: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .aqua-utility-navigation {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0;
            padding-left: 0;

            li {
                display: inline-block;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 160% */
                padding: 6px 20px 6px 0;

                a {
                    color: $white;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: .18px;
                    text-decoration-line: underline;

                    &:hover,
                    &:active,
                    &:focus,
                    &:focus-within,
                    &:focus-visible {
                        text-decoration: none;
                    }
                }
            }
        }

        #toggle-search {
            padding-left: 0;

            .search-label {
                display: none;
            }

            svg {
                width: 16px;
            }

            &:hover,
            &:active,
            &:focus,
            &:focus-within,
            &:focus-visible {
                opacity: 0.5;
            }
        }

        @include media-breakpoint-down(md) {
            .navigation-wrapper {
                align-items: flex-start;
            }
        }

        @include media-breakpoint-up(lg) {
            .navigation-wrapper {
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
            }

            .aqua-utility-navigation {
                justify-content: flex-end;

                li {
                    padding: 0px 21px;
                }
            }

            #toggle-search {
                margin-left: 22px;
            }
        }
    }
}
