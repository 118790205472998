.product-combo-block {
    margin-bottom: 100px;

    .plus {
        font-size: 5rem;
        padding-top: 30px;
        color: #f6895c !important;

        @include media-breakpoint-down(md) {
            padding-top: 0px;
        }
    }

    img {
        border-radius: 12px;
    }

    .price {
        font-size: 2rem;
    }

    h3, p, div {
        text-align: center !important;
    }

    .text-content {
        padding-right: 60px !important;

        @include media-breakpoint-down(md) {
            padding-right: 15px !important;
        }
    }

    .estimate-btn {
        background-color: $aqua-blue;
        text-transform: capitalize;
        border: none;
        color: $white;
        font-size: 1.4rem !important;
        line-height: 2.5rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }
}
