.block {
  .accordion {
    border-radius: 20px;
    border: 1px solid rgba(158, 158, 158, 0.50);
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    max-width: calc(100vw - 4.0rem);
    margin-left: auto;
    margin-right: auto;
    padding: 45px 30px;

    .card {
      border: 0;
      border-radius: 9px;

      &:first-of-type {
        border-radius: 20px;
      }

      .card-header {
        background-color: rgba(255, 255, 255, 1);
        border-bottom: 0;

        button {
          font-family: $font-family-heading;
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 18px;
          text-align: left;
          padding-left: 46px;

          &:before,
          &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: $white;
            background-image: url(/assets/images/aquasystems/minus-black.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 12px 12px;
          }

          &:hover {
            text-decoration: none;
            outline: 0;
            box-shadow: unset;
          }

          &.collapsed {
            &:before,
            &::before {
              background-image: url(/assets/images/aquasystems/plus-black.svg);
            }
          }
        }

        h1 {
          button {
            color: $black;
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 51px; /* 104.615% */
            letter-spacing: -1.08px;

            @include media-breakpoint-up(lg) {
              font-size: 65px;
              line-height: 68px; /* 104.615% */
            }
          }
        }

        h2 {
          button {
            color: $black;
            /* From Figma doc */
            font-size: 44px;
            font-style: normal;
            font-weight: 400;
            line-height: 49.6px; /* 112.727% */
            letter-spacing: -1.536px;
            /* End from Figma doc */

            @include media-breakpoint-up(lg) {
              font-size: 55px;
              line-height: 62px; /* 112.727% */
              letter-spacing: -1.92px;
            }
          }
        }

        h3 {
          button {
            color: $black;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 110% */
            letter-spacing: -0.72px;
            @include media-breakpoint-up(lg) {
              font-size: 40px;
              line-height: 44px;
            }
          }
        }

        h4 {
          button {
            color: $black;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 129.231% */
            letter-spacing: -0.72px;
            @include media-breakpoint-up(lg) {
              font-size: 26px;
              line-height: 33.6px; /* 112.727% */
              letter-spacing: -0.72px;
            }
          }
        }

        h5 {
          button {
            color: $black;
            font-size: 14.4px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 155.556% */
            letter-spacing: 1.8px;
            text-transform: uppercase;
            @include media-breakpoint-up(lg) {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }

        h6 {
          button {
            color: $black;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 29px; /* 150% */
            letter-spacing: -0.54px;
            @include media-breakpoint-up(lg) {
              font-size: 22px;
              line-height: 33px;
            }
          }
        }
      }

      .card-body {
        color: $medium-gray;
        font-size: 14.4px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 166.667% */
        letter-spacing: 0.144px;
        border-radius: 9px;
        padding: 5px 18px 32px 70px;

        a {
          color: $aqua-blue;
          text-decoration: underline;
        }
      }

      &:has(.collapse.show) {
        background-color: rgba(75, 198, 232, 0.10);

        .card-header {
          background: transparent;
        }
      }
    }

    @include media-breakpoint-up(md) {
      max-width: 795px;
      margin: 0 auto;
      padding: 60px 45px;
    }

    @include media-breakpoint-up(lg) {
      padding: 60px 45px;
      .card {
        .card-body {
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0.18px;
          padding: 5px 50px 32px 70px;
        }
      }
    }
  }
}