.feature-banner-block {
    max-height: 100%;
    padding: 80px 0;
    /* P1 (on dark background) */
    font-family: $font-family-body;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    letter-spacing: -0.42px;
    margin-left: -15px;
    margin-right: -15px;
    text-shadow: 1px 1px 2px $dark-gray;

    .row {
        flex-direction: column;
        margin: 0 auto;
        max-width: 1280px !important;

        .secondary-col {
            text-align: left !important;
        }

        .primary-col {

            h2, h3, h4 {
                font-family: $font-family-heading;
                color: $white;
                margin-bottom: 16px;
            }
        }

        .simple-button-block {
            background-image: none;
            color: $white;
            border: solid 1.636px $white;
            border-radius: 4.091px;
            margin-top: 30px;
            padding: 14px 39px;

            .btn-text {
                padding: 0;
                font-size: 14.4px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            svg {
                display: none;
            }

            &:active,
            &:hover,
            &:focus,
            &:focus-within,
            &:focus-visible {
                background-color: $white;
                color: $blue;
            }
        }
    }

    &.center-no-button {
        text-align: center;

        .row {
            .primary-col {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 2.0rem;
                padding-right: 2.0rem;
                text-shadow: 1px 1px 3px $secondary-gray;

                h2 {
                    margin-bottom: 42px;
                }
            }
        }

        .simple-button-block {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 120px 0 120px;
    }

    @include media-breakpoint-up(lg) {
        padding: 179px 0 198px;

        .row {
            .primary-col {
            }

            .simple-button-block {
                font-size: 19.64px;
                line-height: 23.27px;
                padding: 17.5px 43px;

                .btn-text {
                    font-size: 19.636px;
                }
            }

            &.center-no-button {
                .primary-col {
                    max-width: 700px;
                }
            }
        }
    }
}
