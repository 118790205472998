﻿input {
    font-family: $font-family-body;
}

.login-btns {
    form {
        display: inline;
    }
}

.col-lg-6 {

    .EPiServerForms {
        .FormTextbox__Input {
            width:100% !important;
        }
    }
}

.search-button-wrapper {
    padding-left: 0px;

    button {
        height: calc(1.5em + 1.24rem + 2px);

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

.search-input-wrapper {
    padding-right: 0px;
    margin-right: -15px;

    @include media-breakpoint-down(sm) {
        padding-right: 15px;
        margin-right: unset;
    }

    input {
        @include media-breakpoint-down(sm) {
            font-size: 0.95rem;
        }
    }
}

.wt-find-location {
    .search-input-wrapper {

        input {
            font-size: 1.5rem;
            margin-bottom: 10px;

            @include media-breakpoint-down(sm) {
                &::placeholder {
                    text-align: center;
                }
            }
        }
    }

    .search-button-wrapper {

        @include media-breakpoint-down(sm) {
            padding-left: 8px;
        }

        button {
            font-size: 1.5rem;
            height: calc(1.5em + 1.12rem + 2px) !important;
        }
    }
}

.EPiServerForms {
    legend {
        font-family: $font-family-compressed;
        font-size: 1rem;
        color: $medium-gray;
        text-transform: uppercase;
    }

    select {
        padding: .5rem .5rem .7rem .5rem;
    }

    .FormTextbox__Input {
        display: block;
        max-width: 500px;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    input[type=number] {
        width: 150px;
    }

    .FormChoice label  {
        display: block;
    }

    .Form__Element input[type=number]   {
        width: 73px;
    }

    .FormSubmitButton {
        border: none;
        outline: none;
        display: inline-block;
        padding: 5px 27px 7px 27px;
        vertical-align: middle;
        overflow: hidden;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        font-family: $font-family-compressed;
        font-size: 1.4rem;
        background-color: $highlight-color;
    }

    .Form__Element.FormTextbox.ValidationRequired label:after  {
        content: " *";
        color: red;
    }

    textarea  {
        overflow: auto;
        resize: both;
        min-width: 400px;
        min-height: 250px;
    }

    .FormChoice fieldset label {
        margin-right: 1rem;
        /*        @include media-breakpoint-down(sm) {
            display: block !important;
        }*/
    }

    .FormChoice fieldset label input {
        margin-right: .3rem;
    }

    .FormCaptcha {

        .FormCaptcha__Input {
            clear: left;
        }

        button {
            @include media-breakpoint-down(sm) {
                margin-bottom: 0.5rem;
            }
        }

        img {
            max-width: 100%;
            margin-bottom: 0.5rem;
            float: left;
            padding-right: 1rem;

            @include media-breakpoint-down(sm) {
                padding-right: 0rem;
            }
        }

        label {
            &.Form__Element__Caption {
                display: block;
                margin-bottom: 0.5rem;
                border-bottom: 1px solid $light-gray;
            }
        }
    }
}
