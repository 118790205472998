﻿.contact-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .store-info {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        min-width: 15rem;
        margin-bottom: 3rem;
        max-width: 326px !important;

        .location {
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;

            .location-name {
                display: flex;
                flex-direction: row;
            }

            .location-address {
                display: flex;
                flex-direction: column;
            }

            .phone {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                span {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .schedule {
            div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                span {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}
