.block {
  .jump-navigation-menu {
    background-color: $transparent-gray;
    margin: 2.0rem 0;

    .jump-navigation {
      padding: 23px 2.0rem;
      ul {
        list-style-type: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
          color: #656972;
          &.nav-item {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px; /* 100% */
            letter-spacing: 0.6px;
            text-transform: uppercase;
            margin-left: 12px;
            margin-right: 12px;
            opacity: 0.9100000262260437;

            a {
              color: $aqua-blue;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              letter-spacing: 0.6px;
              text-transform: uppercase;
              text-decoration: none;
              line-height: 24px;
              &:hover,
              &:active,
              &:focus,
              &:focus-within,
              &:focus-visible {
                text-decoration: underline;
              }
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        ul {
          display: flex;
          flex-flow: row wrap;
        }
      }

      @include media-breakpoint-up(md) {
        padding: 23px 15px;
      }
    }
  }
}