.block {
  .promo-banner {
    width: 100vw;
    background: rgb(254,197,104);
    background: linear-gradient(90deg, rgba(254,197,104,1) 16%, rgba(246,137,92,1) 32%);
    margin-top: 0;

    .main-copy-col {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: $warm-gray;
      font-size: 15.979px;
      font-style: normal;
      font-weight: 400;
      max-width: calc(100vw - 3.6rem);
      margin-left: auto;
      margin-right: auto;
      padding: 9px 0 6px;
      transition: all 0.4s linear;

      p {
        font-size: 15.979px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
        a {
          transition: all 0.4s linear;
          &:hover {
            font-weight: 600;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    .promo-banner {
      .main-copy-col {
        max-width: 100%;
        padding: 9px 1.0rem 6px;
      }
    }
  }

  @include media-breakpoint-up(lg) {

  }
}