.standard-product {

    .size-notes p {
        font-style: italic;
    }

    hr {
        margin-bottom: 90px !important;
        margin-top: 60px;
    }

    .lifestyle-section {

        h2 {
            max-width: 490px;
        }

        img {
            width: 490px;
        }
    }

    .features {
        ul {
            padding-left: 0px;
            margin-top: 48px;

            lh {
                font-size: 22px;
                text-transform: uppercase;
            }

            li {
                list-style-type: none;
                margin-bottom: 1rem;
                color: #5f5e5e;
            }
        }

        img.download {
            width: 24px;
            margin-right: 16px;
            margin-bottom: 4px;
        }

        ul.feature-benefit {
            margin-top: 146px;

            @include media-breakpoint-down(md) {
                margin-top: 0px;
            }
        }
    }

    .bold-price {
        font-weight: 500;
    }

    .accessories-title {
        font-weight: 600;
    }

    .product-content-block {
        margin-bottom: 0rem !important;
    }

    .product-content-block-copy {


        h3 {
            font-size: 1.5rem !important;
            margin-top: unset;
        }
    }

    .full-gray-fill {
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-color: $light-gray;
        margin-bottom: 4rem;

        .scroll-tab {
            margin-bottom: 0rem;
        }
    }

    #supportmaterials {
        .card {
            a {
                text-decoration: none;
            }
        }
    }
}

.download-icon-wrapper {
  width: auto;
  height: auto;
  position: relative;
}

.download-icon {
  z-index: 9999;
  position: absolute;
  bottom: 7px;
  right: 0px;
  background: $light-gray;
  opacity: 0.5;
}

.download-icon a {
  display: block;
}

.download-icon svg {
  width: 28px;
  height: 28px;
  margin: 0 auto;
  padding: 5px;
  opacity: 0.4;
}

.zoom-gallery .selectors a:hover, .zoom-gallery .selectors a.active {
  border: none;
}

/*Magic Zoom Styles*/
.mz-thumb.mz-thumb-selected {
  img {
    border-bottom-color: $highlight-color !important;
  }
}

.mz-expand .mz-expand-bg {
  display: none !important;
}

.mz-expand {
  background-color: #fff !important;
}

.product-hero {

  h1 {
    border-bottom: .2rem solid $light-gray;
    padding-bottom: .3rem;
  }

  span {
    color: $secondary-gray;
    font-size: 1rem;
    font-weight: 500;
    font-family: $font-family-condensed;
    margin-bottom: -.3rem;
    margin-left: .1rem;
    display: block;
  }

  .product-hero-copy {
    padding-left: 2rem;

    @include media-breakpoint-down(md) {
      padding-left: unset;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 15px;
    }

    ul {
      padding-left: 40px;
    }
  }
}

.stickyTabs {
  top: 51px;
  background-color: #ddd;
  padding-bottom: 1px;

  @include media-breakpoint-down(md) {
    top: 0px;
  }



  ul {
    li {
      list-style-type: none;
    }
  }
}

.product-tabs {
  margin-top: 0px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100%;
}

.tab-container {
  margin-top: 4rem;

  .main-container {
    &.container {
      margin-top: 2rem;
      background-color: $white;
    }
  }

  .scroll-tab {
    margin-bottom: 4rem;
  }

  #specifications {
    .simp-performance {
      margin-top: 3rem;

      @include media-breakpoint-down(md) {
        margin-top: 2rem;
      }
    }

    .simple-rte-block {
      margin-bottom: 3rem;
    }
  }
}

.dark {
  border:none !important;
}


.product-tabs, .search-tabs {

  .tab-content {

    @include media-breakpoint-down(sm) {
      border-top: none;
    }
  }

  .nav-tabs {
    max-width: 80rem;
    margin-bottom: 0rem;
    border-bottom: none
  }

  .nav-tabs .nav-link {
    font-family: $font-family-condensed;
    font-weight: 400;
    font-size: 1.1rem;
    text-transform: uppercase;
    border: none;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      font-size: .8rem;
    }

    @include media-breakpoint-down(sm) {
      text-align: left;
      color: $header-gray;
    }
  }

  a.nav-link.active {
    background-color: $highlight-color;
    border-radius: 0px;
    text-decoration: none;
    color: $white;

    @include media-breakpoint-down(sm) {
      background-color: $body-color;
      color: $white;
      border-radius: 0px;
    }
  }

  a.nav-link:hover {
    background-color: $highlight-color;
    text-decoration: none;
    border-radius: 0px;
    color: $white;
  }
}


.grid-nav {
    a {
        text-decoration: none;
    }

    h2 {
        padding-left: 7px;
        padding-bottom: 4px;
        margin-bottom: 1rem;
        margin-top: -5px;
        border-bottom: none;
    }


    h4 {
        font-size: 1.3rem !important;
    }

    .card-grid {
        padding-left: 0px;
        padding-right: 0px;
    }
}


/*Downloads Tab*/

#supportmaterials {
  h3 {
    font-size: 1.5rem;
  }
}
.tooltip-inner {
  background-color: $light-gray;
  padding: 7px;
}

.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.bs-tooltip-left .arrow::before {
  border-left-color: $light-gray;
}

.featured-download {
  img {
    @include media-breakpoint-down(sm) {
      width: 80px;
    }
  }
}

.downloads-category {
  padding-bottom: 7px;
}


.downloads-table {
  margin-bottom: 2.5rem;

  td {
    padding: .45rem;

    @include media-breakpoint-down(sm) {
      font-size: .8rem;
    }
  }

  thead {
    th {
      white-space: nowrap;
      padding-top: 0rem;
      padding-bottom: .3rem;
      padding-left: .45rem;
      padding-right: .45rem;

      @include media-breakpoint-down(sm) {
        font-size: .8rem;
        padding: .35rem;
      }
    }
  }
}
.table-hover tbody tr:hover {
  background-color: $highlight-color;
}

.document-card-span {
  font-size: .9rem;
  text-align: left;
  display: block;
  color: $secondary-gray;

  @include media-breakpoint-down(sm) {
    font-size: .75rem;
  }
}

.document-card-link {
  display: block;
  margin: 9px 0px 9px 0px;
  font-size: 1rem;
  font-weight: 400;

  @include media-breakpoint-down(sm) {
    font-size: .75rem;
  }
}

.tooltip.show {
  opacity: 1;
}
.card {
  background-color: transparent;
}
.card-wrapper {
    font-weight: 400;
    font-family: $font-family-compressed;
    font-size: 1.2rem;
    line-height: initial;

    @include media-breakpoint-down(lg) {
        font-size: 1rem;
    }

    a {
        text-decoration: none;
    }


    .card-body {
        span {
            font-weight: 800;
        }

        p {
            font-size: 16px;
        }

        img {
            border-radius: 12px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 auto;
        }
    }
}

.card-body-large {
  display: inline-block;

  span {
    font-weight: 400;
    font-family: $font-family-compressed;
    vertical-align: top;
    padding-top: 1rem;
  }

  p {
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      line-height: 1.3rem;
    }
  }
}

.product-icon {
  img {
    width: 150px;
    margin-right: 1rem;

    @include media-breakpoint-down(md) {
      width: 100px;
      margin-right: 0.6rem;
    }

    @include media-breakpoint-down(sm) {
      width: 60px;
      margin-right: 0.6rem;
    }
  }
}

.standard-product {
    padding: 0px 0px;

    @include media-breakpoint-down(sm) {
        padding: 0px;
    }

    .full-gray-fill {
        margin-top: -74px;
    }

    a {
        color: $aqua-blue;
        font-weight: 500;
    }

    .priceButtons {
        display: grid;
        grid-template-columns: repeat(3,1fr);

        button {
            min-height: 63px;
            border: 1px solid $warm-gray;
            border-radius: 5px;
            font-size: 1.2rem;
            text-transform: uppercase;

            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }

            &:nth-child(2) {
                margin: 0px 15px;

                @include media-breakpoint-down(md) {
                    margin: 0px 5px;
                }
            }

            .size-label {
                display: block;
                font-size: .8rem;
            }

            span {
                font-weight: 100;
                font-size: 1.4rem;
                line-height: 1.5rem;
            }
        }
    }

    p {
        color: $dark-gray;
    }

    .category {
        span {
            font-weight: 500;
        }
    }

    .estimate-btn {
        background-color: $aqua-blue;
        text-transform: capitalize;
        border: none;
        color: $white;
        font-size: 1.4rem !important;
        line-height: 2.5rem;
    }

    .estimate-btn {
    }


    .estimate-btn[disabled] {
        background-color: gray;
        cursor: not-allowed;
    }



    .add-on-selection {
        /*        display: flex;
        flex-wrap: nowrap;*/

        label {
            input[type="checkbox"] {
                height: 30px;
                width: 30px;
                margin-right: 10px;
                display: block;
                float: left;
                margin-bottom: 26px;

                @include media-breakpoint-down(md) {
                    height: 26px;
                    width: 26px;
                    margin-bottom: 24px;
                }
            }
            /*
            input[type="checkbox"] + *::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 2rem;
                height: 2rem;
                border-radius: 8px;
                border-style: solid;
                border-width: 1px;
                border-color: $dark-gray;
                cursor: pointer;
                margin-right: 20px;
            }

            input[type="checkbox"]:checked + *::before {
                content: "✓";
                color: $white;
                background-color: $aqua-blue;
                text-align: center;
                font-size: 27px;
                font-weight: 900;
                padding-top: 3px;
                padding-right: 1px;
            }*/

            span {
                color: $black-alt;
                font-weight: 100;
            }
        }
    }


    .active-price-btn {
        background-color: $orange;
        color: $white;
        border: 1px solid $orange !important;
        text-shadow: 1px 2px 2px #914729;
    }


    .warranty-section {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        margin-top: 50px !important;
        margin-bottom: 80px !important;
        text-shadow: 1px 1px 3px #222;
        background-color: #36424a;

        h2 {
            text-align: center;
            margin-bottom: 45px;
            color: $white;
        }

        .warranty-block-grid {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            flex-wrap: wrap;
            padding-left: 0rem;
            padding-right: 0rem;
            max-width: 100%;

            .warranty-wrapper {
                width: 33.3% !important;
                min-width: 380px !important;
                max-width: 100% !important;
                padding-left: 26px;
                padding-right: 26px;

                .warranty-square {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 0.2);
                    border: solid 1px $white;
                    border-radius: 12px;
                    padding-bottom: 15px;
                    padding-top: 15px;

                    .copy-wrapper {
                        text-align: center;


                        .warranty-square-text {
                            text-transform: uppercase;
                            font-size: 14px;
                            color: $white;
                        }

                        .warranty-square-value {
                            font-size: 45px;
                            line-height: 51px;
                            font-family: $font-family-heading;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .video-section {
        margin-top: 50px;
    }
}
