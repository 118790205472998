﻿.link-list-landing-page {

    .link-list-block {
        display:none;
        li {
            margin-bottom: 1rem;

            a {
                text-decoration: none;
                font-weight: 600;
                FONT-FAMILY: $font-family-condensed;
                font-size: 1.3rem;

                &:hover {
                    text-decoration: underline;
                }

                @include media-breakpoint-down(lg) {
                    font-size: 1.14rem;
                }
            }
        }
    }
}
