.product-tabs {
    max-width: calc(100vw - 4.0rem);
    margin-left: auto;
    margin-right: auto;

    .nav-tabs {
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-bottom: 0rem;
        max-width: 100%;

        @include media-breakpoint-down(md) {
            -webkit-box-orient: vertical !important;
            -ms-flex-direction: unset !important;
            flex-direction: column !important;
        }

        .nav-item {
            flex-grow: 0;
            border-radius: 4.091px 4.091px 0 0;
            border: 1.636px solid #C4C4C4;
            border-bottom: 0;
            min-width: 100px;
            margin-right: 1.0rem;

            @include media-breakpoint-down(md) {
                border: none !important;
                margin-bottom: 12px;
            }

            .nav-link {
                color: $black;
                font-family: $font-family-body;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                text-transform: capitalize;
                white-space: nowrap;
                padding: 18px 10px;

                @include media-breakpoint-down(md) {
                    white-space: normal;
                    border: 1.636px solid #c4c4c4;
                    border-radius: 5px;
                }

                &:hover {
                    background-color: #c4c4c4;
                    height: 100%;
                }

                &.active {
                    background-color: $aqua-blue;
                    color: $white;
                    height: 100%;
                }
            }
        }

        @include media-breakpoint-up(md) {
            .nav-item {
                .nav-link {
                    font-size: 18px;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            max-width: 100%;

            .nav-item {
                min-width: 167px;

                .nav-link {
                    font-size: 19.6363px;
                }
            }
        }
    }

    .tab-content {
        border-top: solid 2px #c4c4c4;
        padding: 36px 0;

        @include media-breakpoint-down(md) {
            border-top: none !important;
        }

        h2, h3, h4, h5, h6 {
            margin-bottom: 12px;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
            border-radius: 20px;
            background: rgba(0, 0, 0, 0.20);
        }

        iframe {
            border-radius: 20px;
        }

        .tab-pane {
            h1, h2, h3, h4, h5, h6 {
                margin-bottom: 24px;
            }

            button,
            a {
                margin-bottom: 20px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding-left: 15px;
        padding-right: 15px;

        .nav-tabs {
            margin-bottom: 0;

            .nav-item {
                /*                flex-basis: 167px;*/
                margin-right: 21px;
            }
        }

        .tab-content {
            padding: 65px 0;

            .tab-pane {
                .simple-rte-block {
                    padding-top: 0;
                }

                .youtube-block {
                    max-width: 66.7%;
                }
            }

            button {
                margin-top: 30px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .tab-content {
            .tab-pane {
                .youtube-block {
                    max-width: 50%;
                }
            }

            padding: 73px 0;

            .simple-rte-block {
                padding-right: 2.0rem;
            }
        }
    }

    @include media-breakpoint-up(xl) {
    }
}
