form.EPiServerForms {
    padding: 24px 0;
    font-family: $font-family-body;

    .form-field {
        margin-bottom: 14px;

        &:has(input[type='radio']) {
            display: flex;

            input {
                &[type='radio'] {
                    margin-right: 8px;
                }
            }
        }
    }

    p {
        &.form-field {
            margin-bottom: 0;
        }
    }

    textarea {
        min-height: 130px;
    }

    label {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */

        &:empty {
            display: none;
        }
    }

    p {
        width: 100%;

        label {
            margin-top: 0;
        }

        span {
            &.wpcf7-form-control-wrap {
                display: block;
                width: 100%;
            }
        }
    }

    .Form__Status {
        .Form__Status__Message {
            max-width: 100%;

            h2, h3 {
                margin-bottom: 28px;
            }

            @include media-breakpoint-up(md) {
                max-width: 522px;
                padding: 31px 0 0 0;

                h2, h3 {
                    margin-bottom: 45px;
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 31px 0 34px 0;
            }
        }
    }

    .FormSubmitButton {
        font-family: $font-family-body;
        font-style: normal;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px; /* 166.667% */
        letter-spacing: 0.18px;
        text-transform: capitalize;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 30px;
        }
    }

    input[type='radio'] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        width: 17px;
        height: 17px;
        border: solid 2px rgba(158, 158, 158, 0.50);
        border-radius: 50%;
        position: relative;
        top: 3px;

        &:checked {
            background: url(/assets/images/aquasystems/radio-checkmark.png) transparent no-repeat center center;
        }
    }

    button {
        appearance: none;
        font-family: $font-family-body;
        background-color: $aqua-blue;
        color: $white;
        margin-top: 14px;

        &[type='submit'] {
            appearance: none;
            background-color: $aqua-blue;
            color: $white;
            border-radius: 5px;
            width: 100%;
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &:hover,
        &:active,
        &:focus,
        &:focus-within,
        &:focus-visible {
            box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.10);
        }
    }

    p {
        margin-bottom: 0;
    }

    .form-field,
    p.form-field {
        width: 100%;

        span {
            &.value {
                display: block;

                span {
                    &:has(input) {
                        display: inline-block;
                        padding-right: 14px;
                    }

                    &.input-wrapper {
                        display: inline-block;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            span {
                &.value {
                    span {
                        &:has(input) {
                            width: 50%;
                        }

                        &.input-wrapper {
                            width: 50%;
                        }
                    }
                }
            }
        }

        input[type='radio'] {
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
            width: 17px;
            height: 17px;
            border: solid 2px rgba(158, 158, 158, 0.50);
            border-radius: 50%;
            position: relative;
            top: 3px;

            &:checked {
                background: url(/assets/images/aquasystems/radio-checkmark.png) transparent no-repeat center center;
                border: solid 2px $aqua-blue;
            }
        }

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    .Form__Element {
        label {
            color: $medium-gray;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 160% */
            margin-top: 8px;
            margin-bottom: 12px;

            &.inline {
                margin: 8px 12px 10px;
            }

            &.Form__Element__Caption {
                margin-bottom: 0px;
                margin-top: 0px;
            }
        }

        p {
            label {
                margin-top: 0;
            }
        }
    }
}

.eyebrow-search {
  box-shadow: 0px 0px 20px 0 rgba(0,0,0,0.1);
  .form-group {
    margin-top: 2px;
    margin-bottom: 16px;
  }
}

/*.form-container {
  max-width: calc(100vw - 4.0rem);
  margin-left: auto;
  margin-right: auto;*/

.water-systems {
    
    form.EPiServerForms {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.75);
        border: 1px solid rgba(158, 158, 158, 0.50);
        border-radius: 20px;
        padding: 44px 48px 44px;
    }
}
    form.EPiServerForms {


        fieldset {
            margin-top: 20px;
        }

        .Form__Element {
            margin-bottom: 10px;

            .Form__Element__Caption {
                display: table;
            }
        }

        .ValidationRequired .Form__Element__Caption:after {
            content: " required";
            color: $orange;
        }

        .Form__Element.FormCaptcha {
            margin-top: 20px;
            margin-bottom: 40px;
        }

        .Form__Status {
            display: none;
        }

        @include media-breakpoint-up(md) {
            align-items: flex-start;
            max-width: 522px;
            margin: 1.0rem auto 2.0rem;
            padding: 24px 21px 24px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 522px;
            padding: 65px 72px 50px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 80px;
        }

        input:not([type='radio']):not([type='checkbox']),
        select,
        textarea {
            display: block;
            width: 100%;
            border-radius: 5px;
            border: 1px solid rgba(158, 158, 158, 0.50);
            background: $white;
            color: $medium-gray;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 166.667% */
            letter-spacing: 0.18px;
            max-width: 100%;
            padding: 11px 17px;
            margin-bottom: 0px;
        }

        input:not([type='radio']):not([type='checkbox']) {
            &[type='submit'] {
                appearance: none;
                background-color: $aqua-blue;
                color: $white;
                border-radius: 5px;
                width: 100%;

                &:hover,
                &:active,
                &:focus,
                &:focus-within,
                &:focus-visible {
                    box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.10);
                }
            }
        }

        .submit {
            width: 100%;

            input {
                width: 100%;
            }
        }

        input[type='checkbox'] {
            -webkit-appearance: none;
            appearance: none;
            display: inline-block;
            border-radius: 8px;
            border: 1px solid rgba(158, 158, 158, 0.50);
            background: #FCFAF9;
            width: 24px;
            height: 24px;
            padding: 2px;
            margin-right: 8px;
            position: relative;
            top: 7px;

            & + label {
                margin: 8px 12px 10px 0;
            }

            &:checked {
                background: url(/assets/images/aquasystems/checkbox-checkmark.png) transparent no-repeat center center;
                background-size: 10px;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        max-width: 522px;
    }

    @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
    }
    /*}*/

    .three-column-block {
        &.form-with-location {
            &.row {
                flex-direction: column;
                align-items: center;

                .simple-rte-block {
                    align-items: flex-start;

                    h1, h2, h3, h4, h5, h6, p {
                        text-align: left;
                    }
                }

                .row {
                    &.icon-block {
                        flex-direction: column;
                        margin-left: 0;
                        margin-right: 0;

                        .row {
                            &.product-icon {
                                &.media {
                                    img {
                                        margin-right: 16px;
                                    }

                                    .media-body {
                                        p {
                                            margin-top: 0;
                                        }
                                    }
                                }
                            }
                        }

                        @include media-breakpoint-down(md) {
                            max-width: calc(100vw - 4.0rem);
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }

                .form-container {
                    margin-top: 20px;
                }

                @media screen and (min-width:991px) and (max-width: 1140px) {
                    .row {
                        &.icon-block {
                            flex-direction: column;

                            .col-md-6 {
                                display: flex;
                                padding-left: 0;
                            }

                            .row {
                                &.product-icon {
                                    margin-left: 0;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

                @media screen and (min-width:991px) and (max-width:1440px) {
                    padding-left: 2.0rem;
                    padding-right: 2.0rem;
                }

                @include media-breakpoint-up(md) {
                    .row {
                        &.icon-block {
                            flex-direction: row;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    flex-direction: row;

                    .simple-rte-block {
                        p {
                            max-width: 570px;
                        }
                    }

                    .form-container {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .small-form-container {
        display: flex;
        flex-direction: row;
        align-items: center;


        .simple-rte-block {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            max-width: 522px;

            p {
                margin-top: 4px;
            }
        }

        form {
            border-radius: 20px;
            border: 1px solid rgba(158, 158, 158, 0.50);
            background-color: rgba(255, 255, 255, 0.75);
            max-width: 522px;
            padding: 43px 36px 24px;
            margin-top: 42px;

            input,
            select,
            textarea {
                border-radius: 5px;
                border: 1px solid rgba(158, 158, 158, 0.50);
                background: $white;
                color: $medium-gray;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px; /* 166.667% */
                letter-spacing: 0.18px;
                max-width: 100%;
                padding: 9px 17px;
                text-transform: capitalize;
                margin-bottom: 20px;

                &::placeholder {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px; /* 166.667% */
                    letter-spacing: 0.18px;
                }
            }
        }

        input:not([type='radio']):not([type='checkbox']) {
            &[type='email'],
            &[type='text'],
            &[type='password'] {
                display: block;
                width: 100%;
            }

            &[type='submit'] {
                appearance: none;
                background-color: $aqua-blue;
                color: $white;
                border-radius: 5px;
                width: 100%;

                &:hover,
                &:active,
                &:focus,
                &:focus-within,
                &:focus-visible {
                    box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.10);
                }
            }
        }

        @include media-breakpoint-down(md) {
            .container {
                .row {
                    max-width: calc(100vw - 4.0rem);
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        @include media-breakpoint-up(md) {
            padding-top: 80px;
            padding-bottom: 80px;

            .simple-rte-block {
                justify-content: center;
                align-items: flex-start;
                max-width: 100%;

                h1, h2, h3, h4, h5, h6, p, div, span {
                    text-align: left;
                }

                h1, h2, h3, h4, h5, h6 {
                    max-width: 400px;
                }

                p {
                    max-width: 550px;
                }
            }

            form {
                padding: 44px 65px 26px;
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            padding-top: 100px;
            padding-bottom: 100px;

            form {
                padding: 35px 76px 25px;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-top: 140px;
            padding-bottom: 140px;
        }

        @include media-breakpoint-up(xxl) {
            padding-top: 170px;
            padding-bottom: 170px;
        }
    }

