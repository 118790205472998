
.action-square-block {
    .action-square-block-grid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;


        @include media-breakpoint-down(sm) {
            padding-left: 0rem;
            padding-right: 0rem;
        }

        max-width: 100%;

        .action-square-wrapper {
            

            .action-square {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                background-color: $white;
                border: solid 1px $border-gray;
                border-radius: 12px;
                padding-top: 0px !important;

                @include media-breakpoint-down(sm) {
                    display: inline-flex;
                    display: inline-flex;
                    flex-direction: unset;
                    justify-content: unset;
                    align-items: unset;
                    height: unset;
                    width: 100%
                }

                a {
                    display: block;
                    width: 100%;

                    .image-wrapper {
                        max-height: 418px;
                        object-fit: cover;
                        object-position: 50% 50%;
                    }
                }

                .action-square-image {
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;

                    @include media-breakpoint-down(sm) {
                        border-bottom-left-radius: 12px;
                        border-top-right-radius: 0px;
                    }
                }

                .action-square-text {
                    padding: 15px 0 0 0;
                    height: auto;

                    &.hide-text {
                        display: block;

                        @include media-breakpoint-down(xs) {
                            display: none;
                        }
                    }
                }

                .copy-wrapper {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    height: 100%;
                    text-align: left;
                    padding-left: 1.8rem;
                    padding-right: 1.8rem;

                    &.centered-header {
                        align-items: flex-start !important;
                    }

                    h5 {
                        background-color: transparent;
                        color: $black;
                        font-family: $font-family-heading;
                        font-size: 28px;
                        line-height: 32px;
                        letter-spacing: -0.72px;
                        text-transform: none;
                        padding: 1.0rem 0;
                        
                        @include media-breakpoint-down(sm) {
                                font-size: 20px;
                            }
                    }

                    p {
                        font-family: $font-family-body;
                        font-size: 14.4px;
                        line-height: 24px;
                        color: $medium-gray;
                    }
                }

                .cta-arrow {
                    display: inline-block;
                    position: relative;
                    text-align: left;
                    padding-left: 0px;
                    padding-right: 30px;
                    padding-bottom: 21px;
                    margin-top: 12px;
                    font-size: 18px;
                    line-height: 21.33px;
                    transition: all 0.1s linear;
                    border-bottom: solid 3px transparent;
                    width: auto;

                    &:after,
                    &::after {
                        content: '';
                        position: absolute;
                        background-image: url(/assets/images/aquasystems/right-arrow.svg);
                        background-repeat: no-repeat;
                        width: 18px;
                        height: 16px;
                        bottom: 21px;
                        right: 4px;
                    }

                    &:hover {
                        border-bottom: solid 3px $accent-blue;
                    }
                }

                .action-square-icon {
                    display: none;
                }

                &:hover {
                    .cta-arrow {
                        border-bottom: solid 3px $accent-blue;
                    }
                }
            }
        }

        &.images {
            .action-square-wrapper {
                .action-square {
                    border: 0;
                    background-color: transparent;

                    .image-wrapper {
                        background-color: $dark-gray;
                        border-radius: 12px;
                        overflow: hidden;

                        img {
                            max-width: 100%;
                        }
                    }

                    .copy-wrapper {
                        align-items: center;
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;

                        h5 {
                            padding: 45px 0 1rem 0;
                        }
                    }

                    &:hover {
                        background-color: transparent;

                        img {
                            opacity: 0.7;
                        }

                        .copy-wrapper {
                            padding-left: 0px;
                            padding-right: 0px;
                        }
                    }

                    .cta-arrow {
                        padding-left: 0;
                    }
                }
            }
        }

        &:not(.images) {
            .action-square-wrapper {
                .action-square {
                    &:hover {
                        box-shadow: 7px 7px 38px rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }

    &.no-images {
        .action-square-wrapper {
            .action-square {
                background-color: $white;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .action-square-block-grid {
            .action-square-wrapper {
                .action-square {
                    align-items: flex-start;

                    .copy-wrapper {
                        p {
                            font-size: 16px;
                            line-height: 28px;
                        }
                    }
                }
            }

            &.images {
                .action-square-wrapper {
                    .action-square {
                        padding-top: 8px;

                        .copy-wrapper {
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-top: 68px;

        .action-square-block-grid {
            max-width: 1280px;
            padding-left: 0;
            padding-right: 0;

            .action-square-wrapper {
                max-width: 360px !important;

                .action-square {
                    text-align: left;

                    .copy-wrapper {
                        padding-left: 55px;
                        padding-right: 55px;

                        h5 {
                            font-size: 36px;
                            line-height: 40px;
                        }
                    }

                    .cta-arrow {
                        padding-left: 0;
                        padding-right: 30px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;

        .action-square-block-grid {
            &.block-grid-xl-4 {
                .action-square-wrapper {
                    width: 33.3% !important;
                    min-width: 380px !important;
                    max-width: 100% !important;
                    padding-left: 26px;
                    padding-right: 26px;

                    .action-square {
                        padding-bottom: 48px;
                        padding-top: 48px;

                        .copy-wrapper {
                            h3 {
                                font-size: 40px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 44px; /* 110% */
                                letter-spacing: -0.72px;
                            }

                            .action-square-text {
                                padding-top: 0;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 28px; /* 166.667% */
                                letter-spacing: 0.18px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width:1050px) and (max-width:1340px) {
        padding-left: 2.0rem;
        padding-right: 2.0rem;
    }
}
