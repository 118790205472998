
.block {
  .promo-box-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $font-family-body;
    font-size: 14.4px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 166.667% */
    letter-spacing: 0.144px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    max-width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    .row {
      flex-direction: column-reverse;

      .col-md-5,
      .col-md-7 {
        padding-left: 0;
        padding-right: 0;
      }

      .d-flex {
        justify-content: center;

        &.main-copy-col {
          width: 100%;
          max-width: 100%;
          justify-content: center;
          align-items: flex-start;
          font-size: 14.4px;
          line-height: 24px;

          h4 {
            font-family: $font-family-body;
            font-size: 15px;
            line-height: 15.6px;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          .simple-button-block {
            display: inline-block;
            font-size: 14.4px;
            line-height: 24px;
            border: solid 1.636px $black;
            border-radius: 4.091px;
            padding: 12px 40px;
            margin-top: 36px;
            &:hover {
              background-color: $warm-gray;
              color: $white;
            }
          }
        }
      }

      .col-md-6 {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .product-icon {
        float: none;
        width: 100%;
        padding: 10% 0 0 0;
        &.media {
          .media-body {
            &.d-flex {
              display: none !important;
            }
          }
        }

        img {
          background-color: transparent;
          width: 100%;
          height: auto;
          max-width: 100%;
          border-radius: 20px;
          margin-right: 0;
          padding: 0;
        }
      }
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;

      .row {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;

        .d-flex {
          &.main-copy-col {
            max-width: calc(100vw - 4.0rem);
            margin-left: auto;
            margin-right: auto;
            padding: 0 0 10% 0;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      max-width: 100%;
      padding: 80px 2.0rem;

      .row {
        flex-direction: row;
        padding: 20px 20px;
        margin-left: -15px;
        margin-right: -15px;
        .col-md-6 {
          padding-right: 0;
        }

        .d-flex {
          &.main-copy-col {
            align-items: flex-start;
            width: 90%;
            max-width: 90%;
            padding-right: 4%;
            padding-left: 0;

            h2 {
              text-align: left;
              margin-bottom: 30px;
            }
          }
        }

        .product-icon {
          img {
            max-width: 100%;
            padding: 0;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .row {
        .d-flex {
          &.main-copy-col {
            font-size: 18px;
            line-height: 30px; /* 166.667% */
            letter-spacing: 0.18px;

            .simple-button-block {
              font-size: 19.64px;
              line-height: 23.27px;
              padding: 16px 40px;
            }
          }
        }
      }
    }

    @include media-breakpoint-up(xl) {
      max-width: 100%;
      padding: 142px 15px 140px;
      .row {
        max-width: 1280px;
        .d-flex {
          &.main-copy-col {
            width: 100%;
            padding-left: 0;
            padding-right: 6%;
          }
        }
      }
    }
  }
}