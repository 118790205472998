
.navbar {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0rem;
    padding-right: 0rem;

    &.navbar-expand-lg.tiptop-navbar {
        background-image: url($primary-nav-background);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom: .4rem solid $white;
    }

    &.global-navbar {
        background-image: url($secondary-nav-background);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 1400px) {
            background-image: url($small-nav-background);
        }

        .brand-logo img {
            width: 200px;

            @media (max-width: 1400px) {
                width: 200px;
            }
        }

        li:hover {
            font-style: normal;
            letter-spacing: normal;
        }

        li::after {
            display: none;
        }
    }

    &.mobile-nav {
        position: fixed;
        width: 100%;
        background-image: url($mobile-nav-background);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .dropdown-menu {
        background-color: $navbar-dropdown-color;
        top: 52; /*  height of nav-item  */
        left: unset;
        display: block;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.1s linear;
        text-transform: none;
        margin: 0;
        border-radius: 0;
        border: none;
        font-style: normal;
        font-weight: 500;
        letter-spacing: normal;
        position: static;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        p {
            font-family: $font-family-condensed;
        }

        h3 {
            font-weight: 800;
            font-style: italic;
            font-size: 2rem;
            text-transform: uppercase;
        }

        h5 {
            font-weight: 800;
            font-family: $font-family-compressed;
        }


        li::after {
            content: none;
        }

        li {
            &:last-child::after {
                display: none;
            }
        }

        div[class*="col"] {
            margin-bottom: 0.5rem;
        }

        .dropdown-title {
            font-size: 1.3rem;
            font-weight: 500;
            padding-left: 0.7rem;

            &.dropdown-title-col2 {
                padding-left: 0rem;
            }
        }

        .product-categories {
            list-style: none;
            padding-left: 0px;
            margin-top: 1rem;
            margin-left: 0px;

            li {
                padding-left: 0px !important;
                padding-top: 0px !important;
            }

            .nav-item {
                padding: 10px;
                text-transform: none;
                float: left;
                flex: unset;

                img {
                    float: left;
                    width: 42px;
                    margin-right: 3px;
                    margin-bottom: 5px;
                }

                span {
                    display: block;
                    line-height: 1.4rem;
                    padding-left: 0.7rem;
                    padding-bottom: 1rem;
                }
            }
        }

        ul.link-list-block {
            margin-left: 12px;

            li {
                margin-bottom: 1rem;

                &:hover {
                    font-style: normal;
                    letter-spacing: initial;
                }
            }
        }


        .list-menu a, .dropdown-menu.product-menu a, .link-list-landing-page .link-list-block a {
            text-decoration: none;
            font-weight: 500;
            font-size: 1rem;

            &:hover {
                text-decoration: underline;
            }

            @include media-breakpoint-down(lg) {
                font-size: 1.14rem;
            }
        }
    }

    ul {
        margin-left: -17px;

        @include media-breakpoint-down(lg) {
            margin-left: -15px;
        }

        @include media-breakpoint-down(lg) {
            margin-left: unset;
        }
    }
}













.global-nav-link {
    color: $button-font-color !important;
    font-weight: 500;
}

.global-nav-link:hover, .global-nav-link:active {
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    background-color: $highlight-color;
}

.search-icon {
    fill: $button-font-color;
}


.nav-button {
    position: relative;
    z-index: 1111;
    margin-top: 1.01rem;
    margin-right: 2px;

    @include media-breakpoint-down(sm) {
        margin-top: .6rem;
    }
}

.nav-button #nav-icon3 {
    width: 24px;
    height: 22px;
    display: inline-block;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.nav-button #nav-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 26px;
    background: $white;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out
}

.nav-button #nav-icon3 span:nth-child(1) {
    top: 0
}

.nav-button #nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
    top: 7px
}

.nav-button #nav-icon3 span:nth-child(4) {
    top: 14px
}

.nav-open #nav-icon3 span:nth-child(1) {
    top: 9px;
    width: 0;
    left: 50%
}

.nav-open #nav-icon3 span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.nav-open #nav-icon3 span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.nav-open #nav-icon3 span:nth-child(4) {
    top: 9px;
    width: 0;
    left: 50%
}

.nav-open .main-menu {
    opacity: 1;
    visibility: visible;
    background: rgba(0, 0, 0, 0.92);
    height: 100%;
    font-family: $font-family-condensed;
}

.main-menu .nav li a {
    color: $white;
    font-size: 2.8rem;
    font-weight: 500;
    text-decoration: none;

    @include media-breakpoint-down(sm) {
        font-size: 1.8rem;
        text-transform: uppercase;
    }
}

.main-menu .nav li {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-animation-timing-function: cubic-bezier(.45,.005,0,1);
    -moz-animation-timing-function: cubic-bezier(.45,.005,0,1);
    -o-animation-timing-function: cubic-bezier(.45,.005,0,1);
    animation-timing-function: cubic-bezier(.45,.005,0,1);
}

.nav-open .main-menu .nav li {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: -webkit-transform .1s,opacity .1s;
    transition: transform .1s,opacity .1s;
}

.fixed-top {
    display: none;
}

.nav-open .fixed-top {
    display: block;
    overflow: scroll;

    .fe-btn {
        height: 100%;
        border-radius: 0;
    }
}

.tiptop-navbar {
    font-family: $font-family-condensed;
    font-weight: 400;
    font-size: 0.8rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
    background-color: $white;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .fe-btn {
        padding: 2px 25px 3px 25px;
        margin-top: 1px;
        margin-bottom: 3px;
    }

    ul {
        display: flex;
        flex-direction: row;
        margin-bottom: 0rem;
        padding-left: 0rem;
        align-self: center;

        li {
            display: list-item;
            padding-top: 0rem;
            font-size: .9rem;
            color: #949494;
            margin-right: 2.5rem;
            list-style-image: none;
            list-style-position: outside;
            list-style-type: none;

            a {
                color: #949494;
            }
        }

        li:last-child {
            margin-right: 0rem;
        }
    }
}

.abs-center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}


.global-navbar {
    background: $navbar-color;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.nav .fe-btn {

}

.global-navbar button {
    font-family: $font-family-compressed;
    font-weight: 700;
    z-index: 999;
    text-transform: uppercase;
}

.global-navbar #navbarSupportedContent {
}

.global-navbar .nav-search-icon {
    width: 49px;
    margin-left: 9px;
}

.bg-white {
    background-color: $white !important;
}

.global-nav {


    li::after {
        content: '';
        float: right;
        right: -2px;
        width: 2px;
        height: 65%;
        margin-top: 8px;
        background: $highlight-color;
        -ms-transform: rotate(20deg); /* IE 9 */
        transform: rotate(20deg);
        margin-left: 1px;
        margin-right: 2px;
    }

    li:last-child::after {
        content: none;
        display: none;
    }

    a.nav-link {
        display: flex;
        align-items: center;
    }
}


.global-nav-link {
    font-family: $font-family-condensed;
    font-size: 1.25rem;
    text-transform: uppercase;
    text-decoration: none;

    @include media-breakpoint-down(lg) {
        font-size: 1.1rem;
        line-height: 1.9rem;
    }
}


.global-nav-link {
    padding: 10px 16px 11px 16px !important;
    display: inline;

    @include media-breakpoint-up(xl) {
        padding: 11px 18px 10px 18px !important;
    }

    @include media-breakpoint-down(lg){
        padding: 11px 16px 10px 16px !important;
    }
}

.global-nav-link:hover, .global-nav-link:active, .global-nav li:hover {
    font-weight: 500;
}

/*----Global Nav - Desktop----*/
/* adds some margin below the link sets  */




.persona-col:not(:last-child) {

}

.persona {

    span {
        clear: both;
        display: block;

        @include media-breakpoint-down(lg) {
            font-size: .9rem;
        }
    }

    h2 {
        text-transform: uppercase;
        margin-bottom: .2rem;

        @include media-breakpoint-down(lg) {
            font-size: 1.3rem;
        }
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    img {
        margin-top: 10px;
    }

    .persona-icon {
        max-width: 150px;
        margin-top: -10px;
    }

    .persona-col {
        float: left;
        padding-left: 36px;
        border-right: 3px solid $light-gray;

        img {
            max-height: 36px;
        }
    }

    .persona-slat {

        img {
            margin-top: 0px;
            max-width: 110px;
            margin-right: 10px;
        }
    }

    .persona-col:nth-child(3) {
        border-right: none;
    }
}



.links-landing-list{ 
    li {
        display: inline;
        padding: 0 2rem 0 2rem;
    }
}

.links-landing-list{ 
    lh {
        font-size: 2rem;
        margin-top: -.5rem;
    }
}

/* remove bootstrap dropwdown caret */
.global-nav-link::after {
    display: none;
}
/* shows the dropdown menu on hover */
.navbar .dropdown:hover .dropdown-menu, .navbar .dropdown .dropdown-menu:hover {
    display: block;
    visibility: visible;
    opacity: 1;
    padding-top: 30px;
    transition: visibility 0s, opacity 0.1s linear;
    min-width: 300px;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.07);

    &.double-width-dropdown {
        min-width: 560px;
    }
}


ul.utility-navigation {
    margin-top: -48px;

    @include media-breakpoint-down(md) {
        margin-top: 0px;
    }


    li {
        font-size: 1.1rem;
        text-transform: uppercase;
        list-style: none;

        a {
            color: #fff;
            text-decoration: none;

            @include media-breakpoint-down(sm) {
                font-size: 1.25rem !important;
                font-weight: 400;
            }
        }

        a:hover {
            color: #fff;
            text-decoration: underline;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 1rem;
        padding-left: 0rem;
    }
}

.get-a-quote-btn {
    width: 150px;
    height: 100px;
    margin: 0 0 0 -20px;
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    background: red;
    overflow: hidden;
    position: relative;
}

.center {
    text-align: center;
}

div.skewed-heading-content {
    -ms-transform: skew(20deg);
    -webkit-transform: skew(20deg);
    transform: skew(20deg);
    position: relative;
    right: -50px;
    background-color: $highlight-color;
    padding: 5px 30px 5px 0;
}

div.skewed-heading-bg {
    background-color: $highlight-color;
    display: inline-block;
    margin-right: 48px;
    padding: 0px 20px 0px 0px;
    -ms-transform: skew(-20deg,0);
    -webkit-transform: skew(-20deg,0);
    transform: skew(-20deg,0);
}

#shape {
    height: 0;
    width: auto;
    border-bottom: 58px solid $highlight-color;
    border-left: 20px solid transparent;
    padding: 0 20px 0 20px;
}


.skew-button a {
    border: none;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;

}

.skew-button {
    background: $highlight-color;
    display: block;
    color: $button-font-color;
    font-weight: normal;
    padding: 10px;
    position: relative;
    text-decoration: none;
    transition: background 0.25s;
    -webkit-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-family: $font-family-compressed;
    font-weight: 700;
    font-size: 1.4rem;
    min-width: 155px;
}

.skew-button.triangle:after {
    content: "";
    background-color: $white;
    display: block;
    height: 100%;
    position: absolute;
    left: -10px;
    top: 0;
    width: 22px;
    -webkit-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-15deg);
}


.search {
    width: 22px;
    height: 22px;
    margin-top: -4px;
}

.search-icon-link {
    margin-top: 0rem;
    padding: 19px 30px 14px 25px !important;
}

.search-icon {
    fill: $white;
}

.search-li {
    position: relative;
}

.search-li:hover {
    font-style: normal !important;
    font-weight: 400 !important;
    letter-spacing: 0rem !important;
}


.togglesearch {
    display: none;
    padding-top: 30px;
    padding-bottom: 25px;
    padding-left: 1rem;
    position: absolute;
    border: none;
    background-color: $navbar-dropdown-color;
    width: 561px;
    top: 51px;
    left: -169px !important;
    right: 0;
    text-transform: none;
    margin: 0;
    border-radius: 0;
    z-index: 999;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
}

.togglesearch-text {
    outline: none;
    border-radius: 0rem;
}
.togglesearch-text:focus {
    border-color: $highlight-color;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.togglesearch-btn {
    font-family: $font-family-compressed;
    font-weight: 700;
    font-weight: 700;
    color: $button-font-color;
    text-transform: uppercase;
    background-color: $highlight-color;
    border: none;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    border-radius: 0rem;
    padding-bottom: 10px;
}

.togglesearch-btn:hover {
    color: $button-font-color;
    background-color: $button-hover-color;
}

.togglesearch-btn:focus {
    color: $body-color;
    -webkit-box-shadow: none;
    box-shadow: none;
}


    .page-link:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    // IE10+ CSS here
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .togglesearch .form-group {
            width: 400px;
        }
    }