﻿.banner {
    min-height: 277px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 35px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        min-height: 200px;
    }
}

.banner-content {
    padding: 55px;
    text-align: left;

    @include media-breakpoint-down(md) {
        padding: 23px;
    }
}

.banner-content h1 {
    font-size: 43px;

    @include media-breakpoint-down(md) {
        font-size: 38px;
    }
}

.banner-content p {
    font-size: 1.2rem;
    line-height: 1.7rem;
}

.dark {
    color: white;
    border-top: 11px solid $highlight-color;

    &.banner {
       
    }
}

.light {
    color: black;
}

.thumbnail-with-link {
    height: 53px;
    vertical-align: middle;
}

.thumbnail-with-link a {
    display: inline-block;
}

.product-pages-h3 {
    font-family: $font-family-compressed;
    color: $header-gray;
}
