button,
a {
    font-family: $font-family-body;

    &.cta-arrow {
        display: inline-block;
        position: relative;
        text-decoration: none;
        background-color: transparent;
        text-align: left;
        padding-left: 0px;
        padding-right: 30px;
        padding-bottom: 21px;
        margin-top: 12px;
        font-size: 18px;
        line-height: 21.33px;
        transition: all 0.1s linear;
        border: 0;
        border-bottom: solid 3px transparent;

        @include media-breakpoint-down(md) {
            padding-bottom: 0px !important;
        }

        &:after,
        &::after {
            content: '';
            position: absolute;
            background-image: url(/assets/images/aquasystems/right-arrow.svg);
            background-repeat: no-repeat;
            width: 18px;
            height: 16px;
            bottom: 21px;
            right: 4px;

            @include media-breakpoint-down(md) {
                bottom: 1px;
            }
        }

        &:hover {
            color: $black;
            border-bottom: solid 3px $accent-blue;
        }
    }

    &.simple-button-block {
        background: transparent;
        display: inline-block;
        color: $black;
        font-size: 14.4px;
        line-height: 24px;
        font-weight: 400;
        font-style: normal;
        text-align: center;
        border-radius: 4.091px;
        border: 1.636px solid $black;
        padding: 14px 39px;

        span {
            text-align: center;
            padding: 0;

            &.btn-text {
                padding: 0;
            }
        }

        svg {
            display: none;
        }

        &:active,
        &:hover,
        &:focus,
        &:focus-within,
        &:focus-visible {
            background-color: $white;
        }
    }

    @include media-breakpoint-up(md) {
        &.simple-button-block {
            font-size: 18px;
            line-height: 30px;
        }
    }
}
