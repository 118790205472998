.block {
    .row {

        &.product-content-block, &.product-content-block-center {
            padding-left: 2.0rem;
            padding-right: 2.0rem;

            &.flex-row-reverse {
                .col-md-7 {
                    padding-left: 0px;
                    padding-right: 53px;
                }
            }

            .col-md-5 {
                padding-left: 0;
                padding-right: 0;

                &.product-content-block-image {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        border-radius: 20px;
                        background: #C4C4C4;
                        /*max-height: 418px;*/
                    }
                }
            }

            .col-md-7, .col-md-12 {
                padding-left: 0;
                padding-right: 0;

                &.product-content-block-copy {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    h4 {
                        /*order: 1;*/
                        font-family: $font-family-body;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 15.6px; /* 104% */
                        letter-spacing: 0.75px;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }

                    h3 {
                        /*order: 2;*/
                        font-size: 44px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 49.6px; /* 112.727% */
                        letter-spacing: -1.536px;
                        margin-bottom: 30px;
                    }

                    p {
                        /*order: 3;*/
                    }

                    .simple-button-block {
                        font-family: $font-family-body;
                        /*order: 4;*/
                        text-align: center;
                        padding: 12.5px 40px;

                        &.fe-btn {
                            background: transparent;
                            color: $black;
                            border-radius: 4.091px;
                            border: 1.636px solid #000;

                            .btn-text {
                                padding: 0;
                            }

                            svg {
                                display: none;
                            }

                            &:hover {
                                background-color: $white;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-up(md) {
                padding: 60px 15px;
                align-items: center;

                .product-content-block-copy {
                    padding-left: 4%;
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 80px 15px;

                .col-md-5,
                .col-md-7 {
                    max-width: 50% !important;
                    flex: 0 0 50%;
                }

                .col-md-7 {
                    padding-left: 53px;

                    &.product-content-block-copy {
                        p {
                            margin-bottom: 36px;
                        }

                        .simple-button-block {
                            &.fe-btn {
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 30px; /* 166.667% */
                                letter-spacing: 0.18px;
                            }
                        }
                    }
                }

                .product-content-block-image {
                    img {
                        /*max-width: 602px;*/
                    }
                }
            }

            @include media-breakpoint-up(xl) {

                .col-md-5 {
                    &.product-content-block-image {
                        img {
                            /* max-width: 602px;
              height: 418px;
              object-fit: cover;*/
                        }
                    }
                }
            }
        }

        .col-md-12.product-content-block-copy {
             padding-left: 0px !important; 
        }
    }
}
