footer {
    .footer-logos {
        img {
            max-height: 80px;
            margin-right: 30px;
        }
    }
    &.site-footer {
        background-color: $very-dark-gray;
        color: $white;
        padding-top: 32px;
        padding-right: 2.0rem;
        padding-left: 2.0rem;

        .row {

            .col-12 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                &.subscribe-form-wrapper {
                    padding-bottom: 29px;
                    padding-left: 0;

                    h2 {
                        color: $white;
                        max-width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .nav-list {

            &:first-child {
                padding-left: 0px !important;
            }

            @include media-breakpoint-down(md) {
                padding-left: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
                margin-top: 9px;
            }
        }

        h4 {
            padding-bottom: 20px;
            padding-top: 20px;
        }

        hr {
            color: #DADADA;
            height: 1px;
            width: 100%;
            margin: 0;

            &.full-width {
                width: auto;
                max-width: 100%;
                margin: 0 15px 29px 15px;
            }
        }

        small {
            display: block;
            margin-top: 15px;
            margin-bottom: 49px;
        }

        ul {
            list-style-type: none;
            padding-left: 0;

            &.link-list-block {
                lh {
                    font-size: 26px;
                    padding-top: 78px;
                    margin-bottom: 30px;
                    font-family: $font-family-heading;

                    @include media-breakpoint-down(md) {
                        padding-top: 25px;
                        margin-bottom: 10px;
                    }
                }

                li {
                    padding-bottom: 10px;

                    a {
                        color: $white;
                        text-decoration: none;
                        letter-spacing: -0.42px;

                        &:active,
                        &:hover,
                        &:focus,
                        &:focus-visible,
                        &:focus-within {
                            opacity: 0.5;
                        }
                    }

                    &.schedule {
                        .day {
                            margin-right: 1em;
                        }
                    }
                }
            }
        }

        nav {
            ul {
                &.footer-utility-nav {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-end;
                    padding-top: 20px;
                    padding-left: 0;

                    li {
                        list-style-type: none;
                        font-size: 14.4px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px; /* 166.667% */
                        letter-spacing: 0.144px;
                        padding: 0 12px 0 0;

                        a {
                            color: $white;
                            text-decoration: none;

                            &:active,
                            &:hover,
                            &:focus,
                            &:focus-visible,
                            &:focus-within {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }

        form {
            border-bottom: solid 1px $white;
            padding: 0;

            button {
                &.submit-button {
                    margin-top: -3px;
                }
            }

            input {
                -webkit-appearance: none;
                appearance: none;
                background-color: transparent;
                color: $white;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px; /* 166.667% */
                letter-spacing: 0.18px;
                border: 0;
                padding-bottom: 17px;

                &::placeholder {
                    color: $white;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px; /* 166.667% */
                    letter-spacing: 0.18px;
                }

                &:active,
                &:hover,
                &:focus,
                &:focus-visible,
                &:focus-within {
                    opacity: 0.5;
                }
            }

            button {
                -webkit-appearance: none;
                appearance: none;
                background-color: transparent;
                border: 0;
                padding: 0 0 13px 0;

                .hidden {
                    display: none;
                }
            }
        }

        .social-icons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .social-icon-list {
                display: inherit;
                padding-top: 24px;

                li {
                    height: 25px;
                    margin-left: 18px;

                    &.facebook {
                        width: 13px;
                        fill: $white;
                    }

                    &.instagram {
                        width: 24px;
                        fill: $white;
                    }

                    &.linkedin {
                        width: 25px;
                        fill: $white;
                    }

                    &.twitter {
                        width: 25px;
                        fill: $white;
                    }

                    &.youtube {
                        width: 25px;
                        fill: $white;
                    }

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .row {
                .col-12 {
                    &.nav-column-1,
                    &.nav-column-2 {
                        padding-left: 0;
                    }
                }

                .social-icon-wrapper {
                    padding-right: 0;
                }

                form {
                    width: 60%;

                    input[type='email'] {
                        width: calc(100% - 40px);
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .row {

                .subscribe-form-wrapper {
                    padding-right: 0;
                }

                form {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    input[type='email'] {
                        width: calc(100% - 36px);
                    }
                }

                .social-icons {
                    align-self: center;

                    .social-icon-list {
                        li {
                            margin-left: 9px;
                            margin-right: 9px;
                        }
                    }
                }
            }

            small {
                text-align: center;
            }
        }

        @include media-breakpoint-up(md) {
            .row {
                .col-12 {
                    &.social-icon-wrapper {
                        align-items: flex-end;
                    }

                    &.nav-column-1 {
                        padding-left: 0;
                    }
                }

                form {
                    input {
                        &[type='email'] {
                            width: calc(100% - 50px);
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 118px;

            .row {
                .col-12 {
                    &.mx-0 {
                        padding-left: 0;
                    }
                }

                .col-12 {
                    &.subscribe-form-wrapper {
                        padding-left: 0;
                    }
                }

                .col-6 {
                    &.col-xl-3 {
                        flex: 0 0 22%;
                    }
                }
            }

            hr {
                position: relative;
                margin-left: 0;
                margin-right: 0;

                &.full-width {
                    right: auto;
                    max-width: 1349px;
                    margin: 0 auto 29px;
                }
            }

            h4 {
                font-size: 26px;
                font-style: normal;
                font-weight: 400;
                line-height: 33.6px; /* 129.231% */
                letter-spacing: -0.72px;
                padding-top: 20px;
                padding-bottom: 30px;
            }

            nav {
                .footer-utility-nav {
                    li {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 30px; /* 166.667% */
                        letter-spacing: 0.18px;
                    }
                }
            }

            ul {
                &.nav-list {
                    margin-left: 0;
                    padding-left: 0;
                    list-style-type: none;

                    li {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 30px; /* 166.667% */

                        a {
                        }
                    }
                }

                &.social-icon-list {
                    position: relative;
                }
            }

            form {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 412px;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            .row {
                .col-12 {
                    &.subscribe-form-wrapper {
                        padding-right: 0;

                        h2 {
                            max-width: 415px;
                            margin-bottom: 54px;
                            margin-top: 59px;
                        }
                    }
                }

                .col-6 {
                    padding-left: 12px;
                }
            }

            nav {
                ul {
                    &.footer-utility-nav {
                        li {
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px; /* 166.667% */
                            letter-spacing: 0.18px;
                        }
                    }
                }
            }

            h4 {
                padding-top: 78px;
            }

            form {
                margin-bottom: 63px;
            }
        }
    }
}
