﻿.persona-page {
    .hero-content {
        h3 {
            font-size: 3rem;
            font-family: $font-family-compressed;
        }
        p {
            font-size:1.3rem;
        }
    }

    .persona-list {
        background-color: $highlight-color;
        height: 72px;
        margin-bottom: 8rem !important;

        @include media-breakpoint-down(md) {
            height: 57px;
        }
        .col-4 {
            padding-left:5px;
            padding-right:5px;
        }
        a {
            text-decoration: none;
            font-family: $font-family-condensed;
            font-weight: 400;
            font-size: 2.1rem;
            font-style: italic;

            @include media-breakpoint-down(md) {
                font-size: 1.3rem;
            }
        }

        .active {
            font-family: $font-family-compressed;
            font-weight: 800;
        }

        .active::after {
            content: '';
            width: 0;
            height: 0;
            border: 40px solid transparent;
            border-top-color: $highlight-color;
            position: relative;
            display: block;
            top: 3rem;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

    .thegray {
        background-color: $light-gray;
        padding-top: 2rem;
    }

    h3 {
        font-family: $font-family-condensed;
        font-size: 2rem;
        font-weight: 700;
    }

    h4 {
        font-family: $font-family-body;
        font-size: 1.5rem;
        font-weight: 400;
    }
}
