﻿.featured-related-products {
    text-align: center;
/*    margin-bottom: $standard-block-bottom-margin;*/

    @include media-breakpoint-down(sm) {
        text-align: left;
    }

    h2 {
        text-align: left;
        padding-bottom: 1rem;
    }

    img {
        width: 200px;
        margin-right: 7px;

        @include media-breakpoint-down(sm) {
            width: 100px;
            margin-left: -15px;
            display: block;
            float: left;
        }
    }

    .slat-text {
        padding-top: 1rem;
        display: block;

        @include media-breakpoint-down(sm) {
            margin-left: 1rem;
            min-height:unset;
        }
    }

    a {
        text-decoration: none;
        font-size: 1.2rem;

        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid #bbb;
            font-size: 1rem;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
