.navbar {
    &.mobile-nav {
        background-color: $transparent-gray;
        background-image: none;
        box-shadow: 1px 1px 20px 0 rgba(0,0,0,0.1);

        .logo {
            img {
                margin-left: 0px;
                margin-top: 7px;
                margin-bottom: 6px;
            }
        }

        .nav-button {
            margin-top: 24px;
            margin-right: 16px;

            span {
                img {
                    margin: 0;
                }
            }
        }

        .mobile-brand {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;

            ul {
                &.quick-links {
                    display: flex;
                    list-style-type: none;
                    margin-bottom: 0;
                    margin-top: 24px;
                    padding-left: 0;
                    padding-right: 22px;

                    li {
                        button {
                            border: none;
                            background-color: $very-light-gray;
                            padding: 0px;

                            &:hover,
                            &:active,
                            &:focus,
                            &:focus-within,
                            &:focus-visible {
                                color: $aqua-blue;
                            }
                        }

                        svg path {
                            stroke: $orange;
                        }
                    }
                }
            }

            .nav-button {
                display: block;

                img {
                    display: block;
                    width: 32px;
                    max-width: 100%;
                }
            }

            .main-menu {
                padding-top: 10px;

                .nav {
                    li {
                        text-align: left;
                        margin-top: 6px;

                        a {
                            display: flex;
                            justify-content: space-between;
                            color: $black;
                            font-family: $font-family-heading;
                            font-size: 18.2px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 23.52px; /* 129.231% */
                            letter-spacing: -0.504px;
                            padding: 0.5rem 21px;
                            text-transform: capitalize;
                            border-radius: 5px;
                            margin-right: 10px;
                            margin-left: 16px;

                            .toggle-icon {
                                z-index: 200;

                                svg {
                                    z-index: 201;
                                    transition: all 0.2s linear;
                                }
                            }

                            &.active {
                                background-color: $white;

                                .toggle-icon {
                                    svg {
                                        transform: scaleY(-1);
                                    }
                                }
                            }
                        }

                        ul {
                            &.submenu {
                                display: block;
                                padding-left: 0;
                                margin-top: 9px;
                                display: none;

                                li {
                                    font-family: $font-family-body;
                                    list-style-type: none;

                                    &.submenu-item {
                                        margin-top: 0;
                                        padding: 0;

                                        a {
                                            color: $medium-gray;
                                            font-family: $font-family-body;
                                            font-size: 12.6px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 21px; /* 166.667% */
                                            letter-spacing: 0.126px;
                                        }

                                        &.current-page {
                                            a {
                                                color: $aqua-blue;
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                ul {
                    &.mobile-utility-navigation {
                        padding-left: 0;

                        li {
                            color: $black-alt;
                            font-family: $font-family-body;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px; /* 160% */
                            list-style-type: none;
                            padding: 8px 23px;
                            margin-left: 10px;

                            form {
                                padding-bottom: 6px;
                                display: none;

                                .search-container {
                                    align-items: center;
                                    justify-content: center;
                                    transition: all 0.4s linear;

                                    input {
                                        &[type='search'] {
                                            max-width: 100%;
                                            border-radius: 5px;
                                            border: 1px solid rgba(158, 158, 158, 0.50);
                                            background: #ffffff;
                                            color: $medium-gray;
                                            font-size: 15px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 30px; /* 166.667% */
                                            letter-spacing: 0.18px;
                                            padding-right: 50px;
                                        }
                                    }

                                    button {
                                        position: absolute;
                                        -webkit-appearance: none;
                                        appearance: none;
                                        background: url(/assets/images/aquasystems/arrow.png) $aqua-blue no-repeat center center;
                                        background-size: 18px;
                                        width: 33px;
                                        height: 23px;
                                        flex-shrink: 0;
                                        border: 0;
                                        outline: 0;
                                        border-radius: 6px;
                                        margin-top: 0;
                                        right: 48px;
                                        z-index: 103;

                                        &:hover,
                                        &:active,
                                        &:focus,
                                        &:focus-visible,
                                        &:focus-within {
                                            box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.10)
                                        }
                                    }

                                    span {
                                        &.btn {
                                            display: block;
                                            width: 11px;
                                            padding: 1rem 0;
                                            margin-left: 32px;

                                            svg {
                                                width: 11px;
                                                margin: 0;
                                                padding: 0;
                                            }
                                        }
                                    }
                                }

                                &.active {
                                    display: flex;
                                }
                            }

                            a {
                                color: $black-alt;
                                text-decoration: none;

                                &#toggle-mobile-search {
                                    span {
                                        &.nav-item-icon {
                                            margin-left: 4px;
                                        }
                                    }
                                }

                                &.phone-link {
                                    font-weight: 600;
                                    text-decoration: underline;
                                }

                                .nav-item-icon {
                                    display: inline-block;
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 6px;

                                    svg {
                                        max-width: 20px;
                                    }
                                }

                                &:hover,
                                &:active,
                                &:focus,
                                &:focus-within,
                                &:focus-visible {
                                    color: $aqua-blue;

                                    span {
                                        &.nav-item-title {
                                            display: inline-block;
                                            text-decoration: underline;
                                        }

                                        svg {
                                            fill: $aqua-blue;

                                            path {
                                                stroke: $aqua-blue;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.nav-open {
  .main-menu {
    background-color: $transparent-gray;
    height: calc(100vh - 72px);
    top: 72px;
  }
}