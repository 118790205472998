$body-color: #222222;
// Added border color for table component
$border-gray: #e1e1e1;
$secondary-gray: #313131;
$dark-gray: #404040;
$very-dark-gray: #36424A;
$light-gray: #f0f0f0;
$very-light-gray: #f3f3f3;
$header-gray: #575757;
$white: #ffffff;
$navbar-color: #3d3d3d;
$navbar-dropdown-color: #d3d3d3;
$footer-color: #3d3d3d;
$blue: #1353A2;
$black-alt: #111212;
$medium-gray: #6b6b6b;

/* AQUA SYSTEMS COLORS FROM FIGMA DOC */
$aqua-blue: #1153A1;
$warm-gray: #36424A;
$accent-blue: #4BC6E8;
$transparent-gray: #f3f3f3;
$orange: #F6895C;
$sun-yellow: #FEC568;
