.estimate {

    .total {
        font-size: 24px;
    }

    .title-row div {
        font-weight: 500;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .add-on-title {
        text-transform: uppercase;
        margin-bottom: 7px;
        font-size: 13px;
        color: $header-gray;
    }

    .add-on-upgrade {
        padding-left: 40px;


        @include media-breakpoint-down(sm) {
            padding-left: 15px;
        }
    }

    .sku-label {
        display: none;
        font-weight: 500;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
            display: inline-block;
        }
    }

    .btn {
        background-color: $blue;
        color: $white;

        &:hover {
            -webkit-box-shadow: 5px 5px 25px 10px rgba(0,0,0,0.1);
            box-shadow: 5px 5px 25px 10px rgba(0,0,0,0.1);
        }
    }

    .back-btn {
        border-radius: 4.091px;
        border: 1.636px solid #000;
        padding: 12.5px 40px;
    }

    form {
        &.EPiServerForms {
            margin-top: 3rem;
            max-width: unset !important;
            min-width: 80%;
            display: -ms-flexbox;
            display: inline-block;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            input {
                margin: 10px 0px;
            }

            textarea {
                margin-top: 30px;
            }
        }
    }
}
