.eyebrow-search {
    background-color: $very-light-gray;
    position: absolute;
    z-index: -1;
    top: -98px;
    opacity: 0;
    transition: all 0.4s linear;

    &.active {
        opacity: 1;
        margin-top: 0;
        top: 44px;
        z-index: 1020;
    }

    form {
        z-index: 101;

        .form-group {
            svg {
                color: $black;
                height: 19px;
                margin: 0 21px 0 0;

                path {
                    fill: $black;
                }

                &#close-search {
                    cursor: pointer;
                }
            }

            .search-container {
                align-items: center;
                justify-content: center;

                input {
                    &[type='search'] {
                        max-width: 370px;
                        border-radius: 5px;
                        border: 1px solid rgba(158, 158, 158, 0.50);
                        background: #ffffff;
                        color: $medium-gray;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 30px; /* 166.667% */
                        letter-spacing: 0.18px;
                        padding-right: 50px;
                    }
                }

                button {
                    background: url(/assets/images/aquasystems/arrow.svg) $aqua-blue no-repeat center 2px;
                    background-size: 18px;
                    width: 33px;
                    height: 23px;
                    flex-shrink: 0;
                    border: 0;
                    border-radius: 6px;
                    margin-top: 0;
                    margin-left: -45px;
                    z-index: 103;

                    &:hover,
                    &:active,
                    &:focus,
                    &:focus-visible,
                    &:focus-within {
                        box-shadow: 3px 3px 8px 2px rgba(0, 0, 0, 0.10);
                    }
                }

                span {
                    &.btn {
                        display: block;
                        width: 11px;
                        padding: 1rem 0;
                        margin-left: 32px;

                        svg {
                            width: 11px;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        form {
            .form-group {
                margin-bottom: 20px;

                &.row {
                    .col {
                        flex-direction: row;
                        align-items: center;
                    }
                }
            }
        }
    }
}
