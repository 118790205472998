.free-form-page {
    .simple-rte-block {
        h1 {
            color: white;
            background-color: #1252a3;
            padding: 30px 10px 33px 10px;
            width: 100%;
            max-width: 1280px;
            margin: 0 -50px;
            border-radius: 76px;

            @include media-breakpoint-down(sm) {
                font-size: 40px !important;
            }
        }
    }
}
.grid-nav {
    .simple-rte-block h1 {
        color: white;
        background-color: #1252a3;
        padding: 30px 10px 33px 10px;
        width: 100%;
        max-width: 1280px;
        margin: 0 -50px;
        border-radius: 76px;

        @include media-breakpoint-down(sm) {
            font-size: 40px !important;
        }
    }
}

.site-wide-message .simple-rte-block p {
    line-height: 21px;
}

.simple-rte-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;

    .category {
        font-family: $font-family-body;
        font-size: 15px;
        line-height: 15.6px;
        letter-spacing: 0.75px;
        font-weight: 400;
        text-transform: uppercase;
    }

    &.full-width p {
        max-width: 100%
    }

    .simple-button-block {
        border: solid 1.636px $black;
        border-radius: 4.091px;
        text-decoration: none;
        margin-top: 20px;

        &:hover {
            background-color: $white;
        }
    }
}

@include media-breakpoint-up(lg) {
  .simple-rte-block {
    max-width: 100%;
    .simple-button-block {
      margin-top: 30px;
    }

    p {
      max-width: 800px;
    }
  }
}



