.block {
  .simple-image-block {
    img {
      margin: 45px auto 10px !important;
      max-width: calc(100vw - 4.0rem);
    }

    .notes {
      text-align: center;
      width: 100%;
      max-width: 100%;
      margin-top: 0;
      margin-left: 0 !important;
      margin-right: 0 !important;
      p {
        max-width: 100%;
        padding-left: 1px;
        padding-right: 1px;
        font-size: 13px !important;

      }
    }

    @include media-breakpoint-up(md) {
      img {
        max-width: 100% !important;
        margin: 45px auto 20px !important;
      }
      .notes {
        text-align: left;
        margin-top: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      img {
        max-width: 100%;
      }
    }

    @include media-breakpoint-up(xl) {
      img {
        max-width: 375px;
        margin: 45px;
      }
      .notes {
        max-width: 375px;
      }
    }
  }
}