﻿.water-systems-search-page {
    button, a.a-btn {
        background-color: $aqua-blue;
        text-transform: capitalize;
        border: none;
        color: #fff;
        font-size: 1.4rem !important;
        line-height: 32px;
        height: unset !important;
    }

    a.a-btn {
        font-size: 1rem !important;
        padding: 6px 15px;
        border-radius: 5px;
        text-decoration:none;
    }

    .hours td {
        padding-right: 10px;
        padding-bottom: 5px;
    }
}

.distributor-search-results {
    height: 550px;
    overflow-y: auto;

    @include media-breakpoint-down(md) {
        height: auto;
        overflow-y: visible;
    }
}

.distributor-map {
    height: 550px;

    @include media-breakpoint-down(md) {
        height: 300px;
    }
}

.custom-scrollbar::-webkit-scrollbar-track {
    border: 1px solid $very-light-gray;
    background-color: #fff;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: $light-gray;
}

.distributor-card {
    clear: both;
    margin-bottom: 1.5rem;
    h4 {
        font-weight: 500;
        margin-bottom: 4px;
    }

    .distance {
        text-align: right;
        float: right;
        font-size: .85rem;
        opacity: 0.65;
    }

    .location {
        text-align: left;
        float: left;
    }

    button{
        padding: 10px 20px;
        text-transform: capitalize;
        border-radius: 5px;
    }

}

.gm-style {
    font-family: $font-family-body;
    strong {
        font-weight:600;
    }
}
