.responsive-table {
    font-family: $font-family-body;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    padding: 50px 0;

    h2 {
        margin-bottom: 54px;
    }

    &.table-with-image-header {

        .container {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .active-category {
        padding-left: 30px;
        padding-right: 30px;
        color: $black;
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: 49.6px; /* 112.727% */
        letter-spacing: -1.536px;
        margin-bottom: 42px;

        @include media-breakpoint-down(md) {
            padding-left: 1.0rem;
            padding-right: 2.0rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 60px 0;
        }
    }

    .container {
        max-width: 100%;

        .table-body-container {
            border-top: 0;
            max-width: 100%;

            @include media-breakpoint-down(lg) {
                overflow-x: scroll;
                scrollbar-color: $aqua-blue $light-gray !important;
                scrollbar-width: thin;
                scroll-margin: 60px;

                ::-webkit-scrollbar {
                    width: 4px;
                }

                ::-webkit-scrollbar-thumb {
                    background-color: $aqua-blue !important;
                    border-radius: 12px;
                    z-index: 30;
                }

                scrollbar-face-color: $light-gray;
                scrollbar-shadow-color: $aqua-blue;
                scrollbar-highlight-color: rgba(17, 83, 161, 0.5);
            }

            .row {


                &:last-of-type {
                    &:not(.table-button-row) {
                        .col {
                            border-bottom: solid 1px $border-gray;
                        }
                    }
                }
            }

            &.plan-details {
                .row {
                    &:nth-last-of-type(2) {
                        .col {
                            border-bottom: solid 1px $border-gray;
                        }
                    }

                    &:last-of-type {
                        .col {
                            border-bottom: 0;
                            border-left: 0;
                        }
                    }
                }
            }
        }

        .row {
            position: relative;

            &.table-image-row,
            &.table-button-row {
                flex-wrap: nowrap;

                .col {
                    background-color: transparent;
                    padding-left: 15px;
                    padding-right: 15px;


                    .product-title {
                        color: $black;
                        font-size: 11px;
                        line-height: 15px;
                        letter-spacing: 1.26px;
                        text-transform: uppercase;

                        @media screen and (min-width:1024px) {
                            font-size: 12.6px;
                        }
                    }
                }

                .card-img-top {
                    border-radius: 12px;
                    object-fit: cover;
                }

                .btn {
                    font-size: 10.8px;
                    line-height: 8.4px;
                    font-weight: 400;
                    width: 100%;
                    background-color: $blue;
                    border-color: $blue;
                    text-decoration: none;
                    padding: 12px 20px;
                    margin-bottom: 17px;

                    &:active,
                    &:focus,
                    &:focus-visible,
                    &:focus-within,
                    &:hover {
                        box-shadow: 5px 5px 25px 10px rgba(0, 0, 0, 0.10);
                    }
                }
            }

            &.table-button-row {
                .col {
                    width: 20% !important;

                    .btn {
                        margin-top: 12px;
                    }
                }
            }

            .col {
                min-height: 46px;
                padding-left: 0;
                padding-right: 0;

                &.row-label {
                    align-items: flex-start;
                    width: auto;

                    span {
                        display: inline-block;
                        letter-spacing: 1.3px;
                        padding: 0 3px;
                        margin: 0 5px;

                        &.title {
                            position: relative;
                            font-size: 10.4px;
                            line-height: 12px;
                            font-weight: 600;
                            letter-spacing: 0;
                            z-index: 0;
                        }
                    }

                    &.toggle {
                        span {
                            &.title {
                                margin-left: 1px;
                            }
                        }
                    }
                }

                span {
                    display: inline-block;
                    margin: 15px 5px;

                    &.blue {
                        color: $blue;
                        text-transform: uppercase;
                        letter-spacing: 1.3px;
                    }
                }
            }

            &.header-row {
                font-size: 13px;
                line-height: 21.45px;
                text-transform: uppercase;

                &:not(:first-of-type) {
                    margin-top: 4px;
                }

                .col {
                    border-bottom: solid 1px $border-gray;

                    @media screen and (max-width:990px) {
                        border-bottom: 0;
                    }

                    &.row-label {
                        .title {
                            font-size: 10.4px;
                            line-height: 17.16px;
                            letter-spacing: 1.04px;
                            color: #444444;
                            font-weight: 400;
                        }
                    }

                    &:nth-of-type(even) {
                        background-color: $very-light-gray;
                    }
                }

                .col:not(:last-of-type) {
                    &:not(.toggle) {
                        border-right: solid 1px $border-gray;
                    }
                }
            }

            &.body-row {
                font-size: 13px;

                &:nth-of-type(1) {
                    .col {
                        border-top: solid 1px $border-gray;
                    }
                }

                .col {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    min-height: 44px;
                    border-bottom: solid 1px $border-gray;

                    &:nth-of-type(odd) {
                        background-color: #ffffff;
                    }

                    &:nth-of-type(even) {
                        background-color: transparent;
                    }

                    .title {
                        font-size: 10.4px;
                        line-height: 12px;
                    }

                    &:last-of-type {
                        border-right: solid 1px $border-gray;
                    }

                    &.check {
                        background-image: url(/assets/images/aquasystems/check.png);
                        background-repeat: no-repeat;
                        background-position: 12px center;
                        background-size: 18px 18px;
                    }
                }
            }
        }
    }


    @include media-breakpoint-down(lg) {
        max-width: 99vw;
        overflow-x: hidden;
        margin-left: 0;
        margin-right: 0;
        padding: 60px 0;

        h2 {
            margin-bottom: 28px;
        }

        .row {
            &.header-row {
                border-bottom: 0;

                .col {
                    &.row-label {
                        padding-left: 0;
                        padding-right: 0;
                        text-transform: capitalize;

                        span {
                            &.title {
                                // letter-spacing: 1.04px;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }

            &.table-button-row {
                .col {
                    &:first-of-type {
                        display: none;
                    }
                }
            }

            &.body-row {
                .col {
                    &.row-label {
                        .title {
                            padding-left: 10px;
                            padding-top: 2px;
                            padding-bottom: 2px;
                        }
                    }

                    &:nth-of-type(2) {
                        hr {
                            opacity: 0;
                        }
                    }
                }

                &.column-labels {
                    span {
                        text-align: center;
                        font-size: 10.4px;
                        line-height: 12px;
                        font-weight: 600;
                    }
                }
            }

            .col {
                &.flex-md-row-reverse {
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                }

                &.row-label {
                    width: 25%;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .container {
            padding-bottom: 12px;

            .header-row {
                flex-direction: row;
                width: 100%;
            }

            .sticky-column {
                position: absolute;
                opacity: 0;
                margin-left: -2px;
                border-top: solid 1px $border-gray;

                .col {
                    opacity: 0;

                    .title {
                        opacity: 0;
                    }
                }

                &.show {
                    opacity: 1;

                    .col {
                        opacity: 1;

                        .title {
                            opacity: 1;
                        }
                    }
                }
            }

            .table-body-container {
                .body-row {
                    &.column-labels {
                        border-top: solid 1px $border-gray;
                    }

                    border-left: solid 1px $border-gray;

                    .row-label {
                        .title {
                            opacity: 0;
                        }
                    }
                }

                .sticky-column {
                    z-index: 100;
                    background-color: #ffffff;
                    width: 101px;
                    border-top: solid 1px $border-gray;
                    border-left: solid 2px transparent;

                    .col {
                        justify-content: center;
                        min-height: 44px;
                        border-bottom: solid 1px $border-gray;
                        background-color: rgba(255,255,255,1);
                        padding-left: 15px;
                        padding-right: 3px;

                        span {
                            display: inline-block;
                            letter-spacing: 1.3px;
                            padding: 0;

                            &.title {
                                position: relative;
                                font-size: 10.4px;
                                line-height: 12px;
                                font-weight: 600;
                                letter-spacing: 0;
                                z-index: 0;
                            }
                        }
                    }
                }

                &.show {
                    .sticky-column {
                        opacity: 1;
                        border-top: solid 1px $border-gray;
                        border-left: solid 2px $border-gray;

                        .col {
                            opacity: 1;

                            &:before,
                            &::before {
                                content: '';
                                height: 100%;
                                background-color: #ffffff;
                                width: 2px;
                                position: absolute;
                                left: 0;
                                border-left: solid 1px $border-gray;
                            }

                            .title {
                                opacity: 1;
                            }
                        }
                    }
                }

                &.plan-details {
                    .row {
                        &:last-of-type {
                            border-left: 0;
                            border-bottom: 0;
                        }

                        &:nth-last-of-type(2) {
                            .col {
                                border-bottom: solid 1px $border-gray;
                            }
                        }
                    }
                }
            }

            &.image-table-container {
                max-width: 100%;

                .sticky-column {
                    position: absolute;
                    border-top: 0;
                    opacity: 1;
                }
            }

            .row {
                min-width: 720px;

                .col {
                    &.flex-sm-row-reverse {
                        flex-direction: row-reverse;
                    }

                    &:not(.row-label) {
                        .title {
                            display: block;
                            text-align: center;
                        }
                    }

                    &.row-label {
                        &:not(.toggle) {
                            display: none;
                        }

                        &.toggle {
                            background-color: transparent;
                            padding: 2px 0;
                            min-height: 10px;

                            span {
                                background-color: transparent;
                            }

                            a {
                                display: flex;
                                align-items: center;
                                text-decoration: none;

                                span {
                                    &.minus {
                                        display: inline-block;
                                        position: relative;
                                        top: -1px;
                                    }

                                    &.plus {
                                        display: none;
                                    }
                                }

                                &.collapsed {
                                    span {
                                        cursor: pointer;

                                        &.minus {
                                            display: none;
                                        }

                                        &.plus {
                                            display: inline-block;
                                            position: relative;
                                            top: -2px;
                                        }
                                    }
                                }
                            }

                            &.active {
                                span {
                                    &.plus {
                                        cursor: pointer;
                                        display: none;
                                    }

                                    &.minus {
                                        cursor: pointer;
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                }

                &.table-image-row {
                    .card-img-top {
                        height: 122px;

                        @include media-breakpoint-down(md) {
                            height: unset;
                        }
                    }

                    .col {
                        padding-left: 6.5px;
                        padding-right: 6.5px;

                        &:first-of-type {
                            max-width: 100px !important;
                        }

                        span {
                            &.product-title {
                                margin: 10px 5px;
                            }
                        }
                    }
                }

                &.body-row {
                    .col {
                        &.check {
                            background-position: center 15px;
                            background-size: 15px 15px;
                        }
                    }
                }
            }

            .sticky-column {
                &.image-row {
                    background-color: #f3f3f3;
                    z-index: 100;
                    height: 100%;
                    width: 100px !important;
                    margin-left: -1px;
                }
            }

            .table-image-row-container {
                position: relative;
                max-width: 100%;
                overflow-x: scroll;
                scrollbar-color: $aqua-blue $light-gray !important;
                scrollbar-width: thin;
                scroll-margin: 60px;

                ::-webkit-scrollbar {
                    width: 4px;
                    background-color: $light-gray;
                }

                ::-webkit-scrollbar-thumb {
                    background-color: $aqua-blue !important;
                    border-radius: 12px;
                    z-index: 30;
                }

                scrollbar-face-color: $light-gray;
                scrollbar-shadow-color: $aqua-blue;
                scrollbar-highlight-color: rgba(17, 83, 161, 0.5);
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .container {
            .row {
                &.table-image-row,
                &.table-button-row {
                    .col {
                        .btn {
                            margin-bottom: 14px;
                            font-size: 14.4px;
                            line-height: 1.0;
                        }
                    }
                }

                &.header-row {
                    border-left: solid 1px $border-gray;
                    border-right: solid 1px $border-gray;

                    .col {
                        &.row-label {
                            border-bottom: 0;

                            span {
                                &.title {
                                    margin-left: 24px;
                                    margin-top: 14px;
                                    margin-bottom: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        .container {
            .row {
                &.table-image-row,
                &.header-row {
                    .col {
                        width: 25% !important;
                    }
                }

                &.body-row {

                    .col {
                        &.check {
                            background-position: center center;
                        }

                        &:first-of-type {
                            max-width: 100px !important;
                        }

                        &:not(.row-label) {
                            .title {
                                display: block;
                                text-align: center;
                                font-weight: 500;
                            }

                            hr {
                                display: none;
                            }
                        }

                        &.row-label {
                            flex-direction: row-reverse;
                            justify-content: center;
                            width: 100%;

                            hr {
                                display: flex;
                                flex-direction: row;
                                flex-grow: 1;
                            }
                        }
                    }
                }

                &.table-button-row {
                    .col {
                        padding-left: 10px;
                        padding-right: 10px;
                        border-bottom: 0;

                        &:first-of-type {
                            max-width: 100px !important;
                        }

                        .btn {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 144px;
        padding-bottom: 160px;

        .container {
            .row {
                .row {
                    border-bottom: solid 1px $border-gray;
                }

                &.table-image-row,
                &.table-button-row {
                    .col {
                        width: 20% !important;

                        &.row-label {
                            position: absolute;

                            hr {
                                display: none;
                            }
                        }
                    }
                }

                .table-button-row {
                    .col {
                        border-bottom: 0;
                    }
                }

                &.header-row {
                    &:not(:first-of-type) {
                        margin-top: 15px;
                    }

                    .col {
                        &:nth-of-type(odd) {
                            background-color: $white;
                        }

                        &.row-label {
                            &.toggle {
                                .toggle-collapse {
                                    .plus,
                                    .minus {
                                        display: none;
                                    }
                                }
                            }

                            .title {
                                font-size: 13px;
                                line-height: 21.45px;
                            }
                        }
                    }
                }

                &.body-row {
                    .col {
                        &:nth-of-type(3) {
                            background-color: $white;
                        }

                        &.row-label {
                            // position: absolute;

                            &.toggle {
                                span {
                                    &.minus,
                                    &.plus {
                                        display: none;
                                    }
                                }
                            }

                            .title {
                                font-size: 13px;
                                line-height: 20px;
                            }
                        }

                        .title {
                            font-size: 13px;
                            line-height: 20px;
                        }
                    }
                }
            }

            .table-body-container {
                .body-row {
                    &:not(first-of-type) {
                        border-top: 0;
                    }
                }

                &.collapse {
                    &:not(.show) {
                        display: block;
                    }
                }
            }
        }

        .container {
            .table-image-row-container {
                margin-bottom: -10px;
            }

            .row {
                .table-body-container {
                    overflow-x: auto;

                    .row {
                        &:last-of-type {
                            .col {
                                border-bottom: solid 1px $border-gray;
                            }
                        }
                    }
                }

                .col {
                    min-height: 34.4px;

                    &.row-label {
                        top: auto;
                    }
                }

                &.table-image-row,
                &.table-button-row {
                    .col {
                        padding-left: 10px;
                        padding-right: 10px;

                        &.row-label {
                            position: relative;
                            top: auto;
                        }

                        .title {
                            font-size: 13px;
                        }

                        .btn {
                            font-size: 18px;
                            line-height: 1.0;
                            padding: 18px 22px;
                            margin-top: 14px;
                            margin-bottom: 0;
                        }
                    }
                }

                &.header-row {
                    border-right: solid 1px $border-gray;

                    &:not(:first-of-type) {
                        margin-top: 20px;

                        &.first-header-row {
                            margin-top: 3px;
                        }
                    }

                    .col {
                        border-bottom: solid 1px $border-gray;
                        border-top: solid 1px $border-gray;

                        &:not(:last-of-type) {
                            border-right: 0;

                            &:not(.toggle) {
                                border-right: 0;
                            }

                            &.row-label {
                                border-right: 0;
                                border-bottom: solid 1px $border-gray;
                            }
                        }

                        &.row-label {
                            position: relative;
                            top: auto;
                            background-color: $white;
                            border-right: solid 1px $border-gray;

                            span {
                                &.title {
                                    font-size: 13px;
                                    letter-spacing: 1.3px;
                                    display: inline-block;
                                }
                            }
                        }

                        span {
                            &.blue {
                                margin: 10px 15px;
                            }
                        }
                    }
                }

                &.body-row {
                    .col {
                        font-size: 13px;
                        min-height: 38px;

                        &:first-of-type {
                            border-left: solid 1px $border-gray;
                        }

                        &:last-of-type {
                            border-right: solid 1px $border-gray;

                            &.row-label {
                                border-right: solid 1px $border-gray;
                            }
                        }

                        &.row-label {
                            position: relative;
                            background-color: $white;
                            align-items: flex-start;
                            top: unset;
                        }

                        &.check {
                            background-size: 24px;
                        }

                        hr {
                            position: absolute;
                            top: 0;
                            display: block;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                        }

                        span {
                            margin: 10px 15px;
                        }
                    }
                }
            }
        }
    }
}
